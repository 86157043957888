import React from 'react';
import LoginForm from '../components/Froms/LoginForm/LoginForm';
import PageContainer from '../components/general/PageContainer/PageContainer';
import SplitSection from '../components/general/SplitSection/SplitSection';
import useTitle from '../hooks/useTitle';

const Login = () => {
	useTitle('Log In | Gociety.io');

	return (
		<PageContainer>
			<SplitSection startSection={<LoginForm />} />
		</PageContainer>
	);
};

export default Login;
