import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitch from '../../general/ToggleSwitch/ToggleSwitch';
import styles from './AppDemo.module.css';
import { switchBradingMode } from '../../../redux/slices/checkoutSlice';
import uuid from 'react-uuid';
import phoneFrame from '../../../assets/app demo/phone_frame.png';
import { TbCirclePlus } from 'react-icons/tb';
import { GrFormClose } from 'react-icons/gr';
import LazyImage from '../../general/LazyImage/LazyImage';
import boxDefault from '../../../assets/app demo/box_default.jpg';
import logoDefault from '../../../assets/app demo/logo_default.png';
import Text from '../../general/Text/Text';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import workoutProgramsText from '../../../assets/app demo/workout programs.png';
import { setProgramImage, removeProgramImage } from '../../../redux/slices/checkoutSlice';
import useCustomProperty from '../../../hooks/useCustomProperty';
import { useState } from 'react';

function ImageContainer({ id, color, defaultImage, imageSrc, section, program }) {
	const dispatch = useDispatch();

	const previewImage = (e) => {
		const imageFiles = e.target.files;
		const imageFilesLength = imageFiles.length;
		if (imageFilesLength > 0) {
			const imageSrc = URL.createObjectURL(imageFiles[0]);
			dispatch(
				setProgramImage({
					section: e.target.dataset.section,
					programName: e.target.dataset.program,
					image: imageSrc,
				})
			);
		}
	};

	const removeImage = (e) => {
		dispatch(
			removeProgramImage({
				section: e.currentTarget.dataset.section,
				programName: e.currentTarget.dataset.program,
			})
		);
	};

	const customImage = (
		<>
			{imageSrc ? (
				<>
					<LazyImage
						src={imageSrc}
						alt=''
						className={styles['output-image']}
					/>
				</>
			) : (
				<span className={styles['add-image-icon']}>
					<TbCirclePlus color={color} />
				</span>
			)}
		</>
	);

	return (
		<div className={styles['program-container']}>
			<div
				className={styles['image-container']}
				style={{ borderColor: color }}
			>
				<div className={styles.output}>
					{defaultImage ? (
						<LazyImage
							src={defaultImage}
							alt=''
							className={styles['output-image']}
						/>
					) : (
						customImage
					)}
					<div className={styles['procram-text']}>
						<img
							src={workoutProgramsText}
							alt=''
						/>
					</div>
				</div>
				<label
					htmlFor={id}
					hidden
				>
					Upload Image
				</label>
				<input
					className={styles.input}
					type='file'
					id={id}
					accept='image/*'
					data-section={section}
					data-program={program}
					onChange={previewImage}
					disabled={defaultImage}
				/>
			</div>
			{imageSrc && !defaultImage && (
				<button
					onClick={removeImage}
					type='button'
					className={styles['remove-button']}
					data-section={section}
					data-program={program}
				>
					<GrFormClose />
				</button>
			)}
		</div>
	);
}

function AppDemo({ hasSwitch }) {
	const dispatch = useDispatch();
	const container = useRef(null);

	const [appVw, setAppVw] = useState(0);

	useEffect(() => {
		const createCustomVhVar = () => {
			const appVw = container.current.clientWidth * 0.01;
			document.documentElement.style.setProperty('--app-vw', `${appVw}px`);
			setAppVw(appVw);
		};
		createCustomVhVar();

		new ResizeObserver(createCustomVhVar).observe(container.current);
	}, []);

	const { logo, customMode, brandName, programs, colors } = useSelector((state) => state.checkout);

	const handleBrandingModeChange = () => {
		dispatch(switchBradingMode());
	};

	return (
		<>
			<div
				className={styles.container}
				ref={container}
			>
				<div
					className={styles.app}
					ref={container}
					style={{ backgroundColor: (customMode && colors.backgroundColor) || '#242424' }}
				>
					<div className={styles.logo}>
						{!customMode ? (
							<LazyImage
								src={logoDefault}
								alt='logo'
							/>
						) : (
							<>
								{logo && (
									<LazyImage
										src={logo}
										alt='logo'
									/>
								)}
							</>
						)}
					</div>
					<Text
						className={styles['brand-name']}
						bold
					>
						{customMode ? brandName || 'Brand Name' : 'FitBud'}
					</Text>
					<div className={styles.swiper}>
						<HorizontalSwiper
							slidesPerView={1.2}
							spaceBetween={appVw * 3}
						>
							{programs.carousel.map((program, programIndex) => {
								return (
									<ImageContainer
										section='carousel'
										program={program.name}
										key={programIndex}
										color={(customMode && colors.primaryColor) || '#FEA501'}
										imageSrc={program.image}
										defaultImage={!customMode && boxDefault}
									/>
								);
							})}
						</HorizontalSwiper>
					</div>
					<div className={styles['app-content']}>
						{programs.main.map((program, programIndex) => {
							return (
								<ImageContainer
									section='main'
									program={program.name}
									key={programIndex}
									color={(customMode && colors.primaryColor) || '#FEA501'}
									imageSrc={program.image}
									defaultImage={!customMode && boxDefault}
								/>
							);
						})}
					</div>
				</div>
				<img
					className={styles['phone-frame']}
					src={phoneFrame}
					alt=''
				/>
			</div>
			<div className={`${styles['switch-container']} ${!hasSwitch ? styles['switch-hidden'] : ''}`}>
				<ToggleSwitch
					label='Branding mode'
					name='branding-mode'
					id={uuid()}
					onChange={handleBrandingModeChange}
					checked={customMode}
					leftValue='Default Branding'
					rightValue='Custom Branding'
				/>
			</div>
		</>
	);
}

export default AppDemo;
