import React, { useRef, createContext, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import SwiperCore, { Mousewheel } from 'swiper/core';
import { isMobile } from 'detect-touch-device';
import styles from './PageSwiper.module.css';


SwiperCore.use([Mousewheel]);
export const SwiperContext = createContext(null);

const PageSwiper = ({ children, slidesPerView, setIsLastSection }) => {
	const swiper = useRef(null);

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const isLastSlide = children.length - 1 === currentSlideIndex;

	useEffect(() => {
		if (!setIsLastSection) return;

		if (isLastSlide) {
			setIsLastSection(true);
		} else {
			setIsLastSection(false);
		}
	}, [currentSlideIndex]);
	console.log("swiper-", swiper.current);

	const swipeToNextSection = () => {
		if (!swiper.current) return;

		const swiperInstance = swiper.current.swiper;
		const activeSlideIndex = swiperInstance.activeIndex;

		swiperInstance.slideTo(activeSlideIndex + 1);
	};

	const swipeToPrevSection = () => {
		if (!swiper.current) return;

		const swiperInstance = swiper.current.swiper;
		const activeSlideIndex = swiperInstance.activeIndex;

		swiperInstance.slideTo(activeSlideIndex - 1);
	};

	const swipeToSection = (sectionIndex) => {
		if (!swiper.current) return;

		const swiperInstance = swiper.current.swiper;

		swiperInstance.slideTo(sectionIndex);
	};

	useEffect(() => {
		document.body.style.overflow = 'auto';

		return () => {
			document.body.style.overflow = 'initial';
		};
	}, []);

	return (
		<SwiperContext.Provider
			value={{
				swipeToNextSection,
				swipeToPrevSection,
				swipeToSection,
			}}
		>
			{/* <Swiper     // full height vertical scroll
				ref={swiper}
				mousewheel={{
					forceToAxis: true,
					thresholdDelta: 20,
				}}
				slidesPerView={slidesPerView || 1}
				onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
				speed={isMobile ? 500 : 800}
				direction='vertical'
				modules={[Scrollbar]}
				scrollbar={{ draggable: true }}
				style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
				longSwipesRatio={0}
				allowTouchMove={isMobile}
			>
				{children.map((pageSection, pageSectionIndex) => {
					if (!pageSection) return;
					return <SwiperSlide className={styles.swiper_auto} key={pageSectionIndex}>{pageSection}</SwiperSlide>;
				})}
			</Swiper> */}

			{children.map((pageSection, pageSectionIndex) => {
				if (!pageSection) return;
				return <SwiperSlide className={styles.swiper_auto} key={pageSectionIndex}>{pageSection}</SwiperSlide>;
			})}
		</SwiperContext.Provider>
	);
};

export default PageSwiper;
