import { createSlice } from "@reduxjs/toolkit";
import wireframe from '../../assets/wireframes/wireframe.png';


const initialState = {
    customMode: true,
    brandName: '',
    logo: '',
    name: '',
    email: '',
    ig: '',
    colors: {
        primaryColor: '',
        backgroundColor: '',
    },
    programs: {
        carousel: [
            {
                name: '1 program',
                image: null,
            },
            {
                name: '2 program',
                image: null,
            },
            {
                name: '3 program',
                image: null,
            },
        ],
        main: [
            {
                name: '4 program',
                image: null,
            },
            {
                name: '5 program',
                image: null,
            },
            {
                name: '6 program',
                image: null,
            },
            {
                name: '7 program',
                image: null,
            },
            {
                name: '8 program',
                image: null,
            },
            {
                name: '9 program',
                image: null,
            },
        ],
    },
    plans: [
        {
            image: {
                src: wireframe,
                alt: '',
            },
            title: 'Starter',
            price: 0,
            note: 'Limited clients',
            perks: [
                'Your Branded Portal hosted within Gociety iOS/Android App',
                'Community Feed',
                'Workout Hub',
                'Habit Creator',
                'Cooking Space',
                'App stats',
                'App Management Portal',
                'Stripe Integration',
                'Onboarding Questionnaire',
                'Marketing “Done-For-You” Funnel',
                'Customer Support'
            ],
            selected: true,
            soon: false,
            buttonText: 'Try our beta'
        },
        {
            image: {
                src: wireframe,
                alt: '',
            },
            title: 'Influencer',
            note: 'Unlimited clients',
            price: 179,
            perks: [
                'Your Own Branded iOS/Android App',
                'Community Feed',
                'Workout Hub',
                'Habit Creator',
                'Cooking Space',
                'App Stats',
                'App Management Portal',
                'Stripe Integration',
                'Onboarding Questionnaire',
                'Marketing “Done-For-You” Funnel',
                'Customer Support'
            ],
            selected: false,
            soon: true,
            buttonText: 'Coming soon!'
        },
        {
            image: {
                src: wireframe,
                alt: '',
            },
            title: 'Entrepreneur',
            price: 299,
            note: 'Unlimited Clients',
            perks: [
                'Your Own Branded iOS/Android App',
                'Community Feed',
                'Workout Hub',
                'Habit Creator',
                'Cooking Space',
                'App Stats',
                'App Management Portal',
                'Stripe Integration',
                'Onboarding Questionnaire',
                'Marketing “Done-For-You” Funnel',
                'Customer Support',
                'Exclusive 1-on-1 High-Ticket Coaching Features ',
            ],
            selected: false,
            soon: true,
            buttonText: 'Coming soon!'
        },
    ],
    addons: [
        {
            title: 'Brand Book',
            subtitle: 'We’ll create the best branding that represents you and your business.',
            name: 'brand-book',
            price: 99,
            perks: [
                'Logo Design',
                'Color Palette',
                'Brand Fonts',
                'Personal Style',
            ],
            checked: false,
            pricingNote: "We’ll create the best branding that represents you and your business.",
            buttonText: 'Coming soon!'
        },
        // {
        //     title: 'Stripe Integration',
        //     subtitle: 'For those who want to manage their payments',
        //     name: 'stripe-integration',
        //     price: 199,
        //     perks: [
        //         'Take payments outside of the app',
        //         'Personalized invoices',
        //         'Offer 1:1 coaching payments',
        //         'View your sales analytics',
        //     ],
        //     checked: false,
        //     pricingNote: "For those who want to manage their payments"
        // },
        {
            title: 'Website Development',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            name: 'website-development',
            price: 49,
            perks: [
                'An exclusive website to present your app',
                'Layout the different coaching services you have available',
                'Easily show your prospects what’s included',
                'Increase the perceived value of your app'
            ],
            checked: false,
            pricingNote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            buttonText: 'Coming soon!'
        },
        {
            title: 'Custom App Design or Specific Features',
            subtitle: "Do you have an idea for your app but don’t know if it's possible?",
            name: 'custom-design-features',
            price: null,
            perks: [
                "Add specific features for customers' needs",
                "Create the app of your dreams",
            ],
            checked: false,
            pricingNote: "Do you have an idea for your app but don’t know if it's possible?",
            buttonText: 'Coming soon!'
        },
        // {
        //     title: 'On-Demand Collections',
        //     subtitle: 'One of the most useful tools to re-purpose your social media content',
        //     name: 'collections',
        //     price: 199,
        //     perks: [
        //         'Put all your social media content in one place and offer easy access to your best content',
        //         'Recycle valuable content and have it available for your community, so useful!',
        //     ],
        //     checked: false,
        //     pricingNote: "One of the most useful tools to re-purpose your social media content"
        // },

    ],
};

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        changePlan: (state, action) => {
            state.plans = state.plans.map((plan) => {
                if (plan.title === action.payload.title) {
                    return { ...plan, selected: true };
                }

                return { ...plan, selected: false };
            });
        },
        updateAddons: (state, action) => {
            state.addons = state.addons.map((addon) => {
                if (addon.name === action.payload.name) {
                    return { ...addon, checked: !addon.checked };
                }

                return addon;
            });
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setIg: (state, action) => {
            state.ig = action.payload;
        },
        setPrimaryColor: (state, action) => {
            state.colors.primaryColor = action.payload;
        },
        setBackgroundColor: (state, action) => {
            state.colors.backgroundColor = action.payload;
        },
        setLogo: (state, action) => {
            state.logo = action.payload;
        },
        switchBradingMode: (state) => {
            state.customMode = !state.customMode;
        },
        setBrandName: (state, action) => {
            state.brandName = action.payload;
        },
        setProgramImage: (state, action) => {
            state.programs[action.payload.section] = state.programs[action.payload.section].map(program => {
                if (program.name === action.payload.programName) {
                    return { ...program, image: action.payload.image };
                }

                return program;
            })
        },
        removeProgramImage: (state, action) => {
            state.programs[action.payload.section] = state.programs[action.payload.section].map(program => {
                if (program.name === action.payload.programName) {
                    return { ...program, image: null };
                }

                return program;
            })
        },
        finishWaitlist: (state, action) => {
            const inputs = [
                {name: 'name', value: state.name},
                {name: 'email', value: state.email},
                {name: 'ig', value: state.ig},
                {name: 'brand_name', value: state.brandName},
                {name: 'addons', value: state.addons},
                {name: 'color1', value: state.colors.primaryColor},
                {name: 'color2', value: state.colors.backgroundColor},
                {name: 'plans', value: state.plans},
            ];
            console.log(JSON.stringify(inputs));
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(inputs)
            };
            fetch('https://proximy.io/custom/gociety_add/', requestOptions).then(response => console.log(response.json()))
        }
    },
});

export const { changePlan, updateAddons, setName, setEmail, setPrimaryColor, setBackgroundColor, setLogo, switchBradingMode, setBrandName, setProgramImage, removeProgramImage, finishWaitlist, setIg } = checkoutSlice.actions;

export default checkoutSlice.reducer;
