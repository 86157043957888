import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import PropTypes from 'prop-types';

function LazyImage({ src, alt, className }) {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setIsLoaded(false);

		const img = new Image();
		img.src = src;
		img.onload = () => setIsLoaded(true);
	}, [src]);

	return (
		<>
			{isLoaded ? (
				<img
					className={className}
					src={src}
					alt={alt}
				/>
			) : (
				<Spinner />
			)}
		</>
	);
}

LazyImage.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	className: PropTypes.string,
};

export default LazyImage;
