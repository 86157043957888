import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../general/Text/Text';
import Button from '../../general/Button/Button';
import styles from './PricingTier.module.css';
import formatCurrency from '../../../helpers/formatCurrency';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function PricingTier({ title, price, perks, billingType, buttonText, pricingNote, monthly, soon }) {
	const navigate = useNavigate();

	return (
		<div className={`${styles.container} ${styles.price_boxx} ${soon ? styles.soonbox : ''}`}>
			<div>
				<Text
					element='h3'
					variant='h1'
					className={`${styles.highlight} ${styles.heading}`}
				>
					{title}
				</Text>
				{price == 0 ? (
						<Text
							element='span'
							variant='base3'
							className={styles.highlight}
						>
							FREE <span className={styles.blackColor}>Beta</span>
						</Text>

					) :
					<>
						{price ? (
								<Text variant='base3'>
									<Text
										element='span'
										variant='base3'
										className={styles.highlight}
									>
										{formatCurrency(billingType === 'monthly' ? price : price * 9)}
									</Text>{' '}

									{billingType === 'monthly' ? "per month" : "per year"}
								</Text>

							) :
							<Text
								element='span'
								variant='base3'
								className={styles.highlight}
							>
								BOOK A CALL
							</Text>
						}
					</>
				}


				<Text className={styles.unlimited_txtt}>
					{pricingNote}
				</Text>
				<Button
					text={buttonText}
					onClick={() => navigate('/register')}
				/>
				<div className={styles.perks}>
					{perks.map((perk, perkIndex, perksArray) => {
						return (
							<Text
								key={perkIndex}
								className={`${styles.perk} ${perkIndex + 1 === perksArray.length ? styles.last : ''}`}
							>
								<BsFillPatchCheckFill size={20} />
								{perk}
							</Text>
						);
					})}
				</div>
			</div>

		</div>
	);
}

PricingTier.propTypes = {
	title: PropTypes.string.isRequired,
	price: PropTypes.number,
	perks: PropTypes.arrayOf(PropTypes.string).isRequired,
	billingType: PropTypes.oneOf(['monthly', 'yearly']),
	buttonText: PropTypes.string.isRequired,
	monthly: PropTypes.bool
};

export default PricingTier;
