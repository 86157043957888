import React from 'react';
import CommonSection from '../CommonSection/CommonSection';
import styles from './Services.module.css';


//import images
import appDevelopmentImage1 from '../../../assets/home/home4x/tool1.png';
import appDevelopmentImage2 from '../../../assets/home/home4x/tool2.png';
import appDevelopmentImage3 from '../../../assets/home/home4x/tool3.png';
import appDevelopmentImage4 from '../../../assets/home/home4x/tool4.png';
import appDevelopmentImage5 from '../../../assets/home/home4x/tool5.png';
import appDevelopmentImage6 from '../../../assets/home/home4x/tool6.png';
import useCustomProperty from '../../../hooks/useCustomProperty';

const slides = [
	{
		image: appDevelopmentImage1,
		heading: 'Community Feed',
		text: `The coolest place for your community to share what they’re eating, their workout, and their wins. It can also be the safest place to be vulnerable, find support from like-minded people, and get motivation on hard days to keep going. One Tribe. One App.`,
	},
	{
		image: appDevelopmentImage2,
		heading: 'workout hub',
		text: `Share your latest workout. Create fun and viral Challenges. Assign basic, advanced, and hybrid workouts to your clients. Personalize everything.`,
	},
	{
		image: appDevelopmentImage3,
		heading: 'habit creator',
		text: `Weekly Planner. Daily Challenges. Motivator Push-Notifications. Keep your Tribe accountable.`,
	},
	{
		image: appDevelopmentImage4,
		heading: 'cooking space',
		text: `An engaging library full of delicious and easy-to-make recipes. You can also customize, create and share new recipes with your community.`,
	},
	{
		image: appDevelopmentImage5,
		heading: 'the essentials',
		text: `AI Integrations. Body Type Quiz. Health App Integrations. Suggested Daily Calories. Macro Tracker. Progress Tracker. You know… the usual.`,
	},
	// {
	// 	image: appDevelopmentImage6,
	// 	heading: 'video-calls & messaging',
	// 	text: `Offer exclusive 1:1 coaching video calls to your high-ticket clients. Send real-time messages with videos & photos.`,
	// },
];

const Services = () => {
	const primaryColor = useCustomProperty('--clr-primary-high');
	const weightMedium = useCustomProperty('--medium');
	const weightBold = useCustomProperty('--bold');
	const padding30 = useCustomProperty('--padding30');
	const padding100 = useCustomProperty('--padding100');

	return (
		<CommonSection
			slides={slides}
			reverse
			title={
				<>
					<div style={{ fontWeight: weightMedium, paddingBottom: padding30 }} className={styles.padding20m}>
						THE TOOLS OF THE
						<br />
						<span style={{ color: primaryColor, fontWeight: weightBold }}>NEW LEADER</span>
					</div>
				</>
			}
			subtitle={
				<>
					<div style={{ paddingBottom: padding100 }} className={`${styles.padding30m} ${styles.mob_font24}`}>
						We Equip Your App With The Most Essentials Tools and Features Your Clients Will Ever Need.
					</div>
				</>
			}
		/>
	);
};

export default Services;
