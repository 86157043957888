import React from 'react'
import styles from './Ourpartners.module.css';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Text from '../../general/Text/Text';
import whyimg from "../../../assets/home/Iron-Cowboy-2-1.jpg"
import whyimgmob from "../../../assets/home/partner_mob2.jpg"
import ss from "../../../assets/home/Untitled-1.png"
import ss1 from "../../../assets/home/Untitled.png"
import Button from '../../general/Button/Button';


const OurPartner = () => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.row}>
					<div className={styles.colcontent70}>
						<div className={styles.leftcontentholder}>
							<div className={styles.block}>
								<div className={styles.heading}>
									<Text
										element='h2'
										variant='h1'
										className={styles.mobile_title_positoin}
									>
										<div className={styles.mobile_title_div}>
											<span className={styles.main_head}>Our partners</span>
											<br />
											<span className={styles.highlight}>JAMES “THE IRON COWBOY” LAWRENCE</span>
										</div>
										<div className={styles.mob_img}>
											<img src={whyimgmob} width='100%' />
											<Button
												className={styles.button}
												text='WEBSITE'
												onClick={() => window.open('https://www.ironcowboy.com/')}
											/>
										</div>
										{/* <span className={styles.highlight2}>GUINNES WORLD RECORD BREAKER & FAMILY MAN</span> */}
									</Text>
									<Text variant='base4'>
									<span className={styles.font28}>
										“The Iron Cowboy” and “The toughest man on the planet”, James Lawrence is an ultra-endurance
										athlete who has pushed himself beyond what many doctors previously believed to be physiologically possible.
									</span>
									</Text>
									<br />
									<Text variant='base4'>
									<span className={styles.font28}>
										James set the current world record with 50 Ironman-distance triathlons completed within a single
										calendar year. Later on, he broke that record in 2021 during the “Conquer 100” in which he completed
										100 consecutive Ironman-length triathlons in 100 days.

									</span>
									</Text>
									<br />
									<Text variant='base4'>
									<span className={styles.font28}>
										Husband and father of 5 children, James has inspired millions with his message about the resiliency
										of the human spirit and the power our decisions can have over our circumstances
									</span>
									</Text>
									<div className={styles.ss_images}>
										<img src={ss1} width='50%' />
										<img src={ss} width='50%' />
									</div>
									{/* <div className={styles.ss_btnnns}>
									<Button
										className={styles.button}
										text='WEBSITE'
									/>
									<Button
										className={styles.button}
										text='App'
									/>
								</div> */}
								</div>
							</div>
						</div>

					</div>
					<div className={styles.colimage30}>
						<div className={styles.desk_img}>
							<img src={whyimg} width='100%' />
							<Button
								className={styles.button}
								text='WEBSITE'
								onClick={() => window.open('https://www.ironcowboy.com/')}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default OurPartner;
