import React from 'react'
import styles from './Footermain.module.css';
import Text from '../../general/Text/Text';
import { HashLink } from 'react-router-hash-link';


const Footermain = () => {
    return (
        <div className={styles.footer_main}>
            <div className={styles.footer_main_inner}>
                <div className={`${styles.col_33} ${styles.order_1}`}>
                    <Text className={styles.footer_head}>
                        features
                    </Text>
                    <HashLink to={'/features#section1'} className={styles.link_footer}>
                        COMMUNITY FEED
                    </HashLink><br />
                    <HashLink to={'/features#section2'} className={styles.link_footer}>
                        WORKOUT HUB
                    </HashLink><br />
                    <HashLink to={'/features#section3'} className={styles.link_footer}>
                        HABIT CREATOR
                    </HashLink><br />
                    <HashLink to={'/features#section4'} className={styles.link_footer}>
                        COOKING SPACE
                    </HashLink>
                </div>
                <div className={`${styles.col_33} ${styles.order_3}`}>
                    <Text className={styles.footer_head}>
                        pricing
                    </Text>
                    <HashLink to={'/pricing#addons'} className={styles.link_footer}>
                        ADDONS
                    </HashLink><br />
                    <HashLink to={'/pricing#income'} className={styles.link_footer}>
                        potential app income
                    </HashLink><br />
                    {/*<HashLink to={'/pricing#manager'} className={styles.link_footer}>*/}
                    {/*    app manager*/}
                    {/*</HashLink><br />*/}
                    {/*<HashLink to={'/pricing#strategy'} className={styles.link_footer}>*/}
                    {/*    marketing strategy*/}
                    {/*</HashLink><br />*/}
                    {/*<HashLink to={'/pricing#book'} className={styles.link_footer}>*/}
                    {/*    brand book*/}
                    {/*</HashLink>*/}
                </div>
                <div className={`${styles.col_33} ${styles.order_2}`}>
                    <Text className={styles.footer_head}>
                        company
                    </Text>
                    <HashLink className={styles.link_footer}>
                        about us
                    </HashLink><br />
                    <HashLink className={styles.link_footer}>
                        terms
                    </HashLink><br />
                    <HashLink className={styles.link_footer}>
                        privacy
                    </HashLink><br />
                    <HashLink className={styles.link_footer}>
                        contact us
                    </HashLink>
                </div>
            </div>
        </div>
    )
}

export default Footermain
