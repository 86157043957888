import React from 'react';
import styles from './SubscriptionForm.module.css';
import Button from '../../general/Button/Button';
import Input from '../../general/Input/Input';
import { useState } from 'react';
import Joi from 'joi';
import tlds from 'tlds';
import { useNavigate } from 'react-router-dom';

const schema = Joi.object({
	email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: tlds } }),
});

const SubscriptionForm = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState({ value: '', error: '' });
	const [buttonText, setButtonText] = useState('Try our beta');
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const handleInputChange = (e) => {
		setEmail((prev) => ({ ...prev, value: e.target.value }));
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		navigate('/register')

		// const errorMessage = schema.validate({ email: email.value }).error?.message;
		//
		// setEmail((prev) => ({ ...prev, error: errorMessage }));
		//
		// if (!errorMessage) {
		// 	const inputs = [
		// 		{name: 'email', value: email.value},
		// 	]
		// 	console.log(JSON.stringify(inputs));
		// 	const requestOptions = {
		// 		method: 'POST',
		// 		headers: { 'Content-Type': 'application/json' },
		// 		body: JSON.stringify(inputs)
		// 	};
		// 	fetch('https://proximy.io/custom/gociety_add/', requestOptions).then(response => console.log(response.json()))
		//
		// 	setButtonText('Thank you');
		// 	setButtonDisabled(true);
		// }
	};


	return (
		<form className={styles.form}>
			{/*<Input*/}
			{/*	value={email.value}*/}
			{/*	type='email'*/}
			{/*	name='email'*/}
			{/*	label=""*/}
			{/*	id='email'*/}
			{/*	placeholder='Enter your email'*/}
			{/*	onChange={handleInputChange}*/}
			{/*	error={email.error}*/}
			{/*	variant='contained'*/}
			{/*	className={styles.email_input}*/}
			{/*/>*/}
			<Button
				className={styles.button}
				type='submit'
				onClick={handleFormSubmit}
				text={buttonText}
				disabled={buttonDisabled}
			/>
		</form>
	);
};

export default SubscriptionForm;
