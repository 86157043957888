import React from 'react';
import Text from '../../general/Text/Text';
import BrandingAddOn from '../../Branding/BrandingAddOn/BrandingAddOn';
import styles from './Addons.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddons } from '../../../redux/slices/checkoutSlice';

const Addons = () => {
	const dispatch = useDispatch();

	const addons = useSelector((state) => state.checkout.addons);

	const handleAddonChange = ({ target }) => {
		dispatch(updateAddons({ name: target.name }));
	};

	return (
		<>
			<Text
				element='h4'
				className={styles.heading}
			>
				Select Addons
			</Text>
			<div className={styles.addons}>
				{addons.map((addon, addonIndex) => {
					return (
						<BrandingAddOn
							key={addonIndex}
							id={addonIndex + addon.name}
							name={addon.name}
							label={addon.title}
							value={addon.title}
							price={addon.price}
							checked={addon.checked}
							onChange={handleAddonChange}
							subtitle={addon.subtitle}
						/>
					);
				})}
			</div>
		</>
	);
};

export default Addons;
