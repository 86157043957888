import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import tlds from 'tlds';
import AuthForm from '../AuthForm/AuthForm';

const inputs = [
	{ type: 'email', id: 'email', label: 'Enter you email', placeholder: 'Email', name: 'email' },
	{ type: 'password', id: 'password', label: 'Enter you password', placeholder: 'Password', name: 'password' },
];

const schema = Joi.object({
	password: { value: Joi.string().pattern(new RegExp('^[a-zA-Z0-9]{3,30}$')).required(), error: '' },
	email: {
		value: Joi.string()
			.email({ minDomainSegments: 2, tlds: { allow: tlds } })
			.required(),
		error: '',
	},
});

const LoginForm = () => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({ password: { value: '', error: '' }, email: { value: '', error: '' } });

	const handleInputChange = ({ target }) => {
		setFormData((prev) => ({ ...prev, [target.name]: { ...prev[target.name], value: target.value } }));
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		const errorMessage = schema.validate(formData).error?.message;

		navigate('/branding');
	};

	return (
		<AuthForm
			title='Log In'
			subtitle='Lorem Ipsum Dolor Siet Met'
			inputs={inputs}
			buttonText='Log In'
			altLink='register'
			handleFormSubmit={handleFormSubmit}
			handleInputChange={handleInputChange}
		/>
	);
};

export default LoginForm;
