import React from 'react';
import styles from './ToggleSwitch.module.css';
import { TfiClose } from 'react-icons/tfi';
import { RiCheckLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import Text from '../Text/Text';

const ToggleSwitch = ({ label, onChange, checked, name, id, leftValue, rightValue, color }) => {
	return (
		<div className={`${styles.container} ${color === 'light' ? (`${styles.lightContainer}`) : (``)}`}>
			<Text bold>{leftValue}</Text>
			<div className={styles.toggle}>
				<label
					htmlFor={id}
					hidden
				>
					{label}
				</label>
				<input
					type='checkbox'
					className={styles.checkbox}
					name={name}
					id={id}
					checked={checked}
					onChange={onChange}
				/>
				<label
					className={styles['outer-container']}
					htmlFor={id}
				>
					<div className={styles.switch} />
				</label>
				{/* <RiCheckLine color='black' />
				<TfiClose color='black' /> */}
			</div>
			<Text bold>{rightValue}</Text>
		</div>
	);
};

ToggleSwitch.propTypes = {
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	leftValue: PropTypes.string,
	rightValue: PropTypes.string,
};

export default ToggleSwitch;
