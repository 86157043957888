import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { isMobile } from 'detect-touch-device';

const HorizontalSwiper = ({ children, currentSlide, allowTouchMove, swipeTo, slidesPerView, spaceBetween = 50 }) => {
	const swiper = useRef(null);

	useEffect(() => {
		if (!swiper.current) return;
		const swiperInstance = swiper.current.swiper;

		swiperInstance.slideTo(currentSlide);
	}, [currentSlide, swiper.current]);

	return (
		<Swiper
			centeredSlides={false}
			slidesPerGroup={1}
			slidesPerView={slidesPerView || 1}
			slidesPerGroupAuto={true}
			spaceBetween={spaceBetween}
			ref={swiper}
			speed={200}
			allowTouchMove={allowTouchMove}
			onSlideChange={(self) => swipeTo && swipeTo(self.activeIndex)}
		>
			{children.map((child, index) => {
				return <SwiperSlide key={index}>{child}</SwiperSlide>;
			})}
		</Swiper>
	);
};

export default HorizontalSwiper;
