import React from 'react';
import PageContainer from '../components/general/PageContainer/PageContainer';
import useTitle from '../hooks/useTitle';
import Header from '../layout/Header/Header';

const Blog = () => {
	useTitle('Blog | Gociety.io');

	return (
		<PageContainer>
			<Header />
			<main></main>
		</PageContainer>
	);
};

export default Blog;
