import React from 'react';
import styles from './Input.module.css';
import { MdError } from 'react-icons/md';
import Text from '../Text/Text';
import PropTypes from 'prop-types';

const Input = ({ id, name, type, label, value, error, placeholder, onChange, variant, required }) => {
	return (
		<div className={`${styles.container} ${styles.subs_input}`}>
			<label
				htmlFor={id}
				hidden
			>
				{label}
			</label>
			<input
				className={`${styles.input} ${variant === 'contained' ? styles.contained : ''}`}
				id={id}
				name={name}
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				required={required}
			/>
			{error && (
				<Text className={styles.error}>
					<MdError />
					{error}
				</Text>
			)}
		</div>
	);
};

Input.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	error: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	variant: PropTypes.oneOf(['contained', 'default']),
	required: PropTypes.bool
};

export default Input;
