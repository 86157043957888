import { useEffect, useState } from 'react';

const useCustomProperty = (property) => {
    const [customPropertyValue, setCustomPropertyValue] = useState();

    useEffect(() => {
        const propertyValue = getComputedStyle(document.documentElement).getPropertyValue(property);
        setCustomPropertyValue(propertyValue);
    }, [property])
    return customPropertyValue;
};

export default useCustomProperty;