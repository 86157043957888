import React from 'react';
import Text from '../../general/Text/Text';
import styles from './BrandingAddOn.module.css';
import { MdOutlineAddShoppingCart } from 'react-icons/md';
import { HiCheckCircle } from 'react-icons/hi';
import formatCurrency from '../../../helpers/formatCurrency';

function BrandingAddOn({ id, name, label, value, price, checked, onChange, subtitle }) {
	return (
		<>
			<label
				htmlFor={id}
				className={styles.label}
			>
				<div className={styles.heading}>
					<Text
						element='base1'
						className={styles.title}
					>
						{label}
					</Text>
					<Text>{subtitle}</Text>
				</div>
				<div className={styles.price}>
					<Text
						element='span'
						bold
					>
						{price ? formatCurrency(price) : 'BOOK A CALL'}
					</Text>
					<Text
						element='span'
						className={`${styles.icon} ${checked ? styles.checked : ''}`}
					>
						{checked ? <HiCheckCircle /> : <MdOutlineAddShoppingCart />}
					</Text>
				</div>
			</label>
			<input
				onChange={onChange}
				className={styles.checkbox}
				type='checkbox'
				name={name}
				id={id}
				checked={checked}
				value={value}
			/>
		</>
	);
}

export default BrandingAddOn;
