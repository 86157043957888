import React, { useState, useEffect } from 'react';
import PageSwiper from '../components/general/PageSwiper/PageSwiper';
import Footer from '../components/HomePage/Footer/Footer';
import Hero from '../components/HomePage/Hero/Hero';
import HowItWorks from '../components/HomePage/HowItWorks/HowItWorks';
import Services from '../components/HomePage/Services/Services';
import Testimonials from '../components/HomePage/Testimonials/Testimonials';
import WhyUs from '../components/HomePage/WhyUs/WhyUs';
import useTitle from '../hooks/useTitle';
import { isMobile } from 'detect-touch-device';
import PageContainer from '../components/general/PageContainer/PageContainer';
import OurPartner from '../components/HomePage/Ourpartners/OurPartners';
import Footermain from '../components/general/Footermain/Footermain';
import PotentialIncome from '../components/general/PotentialIncome/PotentialIncome';


const Home = () => {
	useTitle('Gociety.io');
	const [isLastSection, setIsLastSection] = useState(false);
	// const [isScroll, setisScroll] = useState(false);

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		setisScroll(true)
	// 	}
	// 	window.addEventListener('scroll', handleScroll)
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, [])

	const partialViewStyle = {
		transform: isLastSection && 'translateY(50vh)',
		transition: `transform ${isMobile ? 500 : 800}ms`,
	};
	return (
		<PageContainer>
			<PageSwiper setIsLastSection={setIsLastSection}>
				<Hero style={{ display: 'none' }} />
				<Services />
				<WhyUs />
				<Footer />
				<OurPartner />
				{/* <div style={partialViewStyle}> */}
				<PotentialIncome />
				{/* </div> */}
				{/*<HowItWorks />*/}
				<Testimonials />

				<Footermain />
			</PageSwiper>
		</PageContainer>
	);
};

export default Home;
