import React from 'react';
import styles from './SplitSection.module.css';
import Header from '../../../layout/Header/Header';
import coverImage from '../../../assets/general/auth.jpg';

const SplitSection = ({ startSection }) => {
	return (
		<section className={styles.container}>
			<Header
				// transparentBg
				// lightMode
			/>
			<div className={styles.block}>
				<div className={styles['start-block']}>{startSection}</div>
				<div className={styles['end-block']}>
					<img
						className={styles.image}
						src={coverImage}
						alt='greek statue of hercules'
					/>
				</div>
			</div>
		</section>
	);
};

export default SplitSection;
