
import React from 'react';
import styles from './HowItWorks.module.css';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import FadeInOutSwiper from '../../general/FadeInOutSwiper/FadeInOutSwiper';
import useStep from '../../../hooks/useStep';
import Navigation from '../Navigation/Navigation';
import Text from '../../general/Text/Text';
import Pagination from '../../general/Pagination/Pagination';
import { ArrowButtons } from '../Navigation/Navigation';
import Button from '../../general/Button/Button';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';

//import images
import branding0 from '../../../assets/home/home4x/Howitworks1.png';
import branding1 from '../../../assets/home/home4x/Howitworks2.png';
import branding2 from '../../../assets/home/home4x/Howitworks3.png';
import branding3 from '../../../assets/home/home4x/Howitworks4.png';

const slides = [
	{
		image: branding0,
		heading: <>
			<span>Step 1:</span> custom Branding</>,
		text: `Time to design your app! Upload your logo, set your colors theme, and choose the pictures you want in the app.`,
	},
	{
		image: branding1,
		heading: <><span>Step 2:</span> upload your content</>,
		text: `We created a friendly easy-to-use backend for you to manage the app without killing you in the process. 
				Create your workouts, select your exercises and upload your videos.`,
	},
	{
		image: branding2,
		heading: <><span>Step 3:</span> set up pricing</>,
		text: `You decide what content is free (like a free 30-day challenge for new visitors) and what content is 
				paid (like your workouts for your subscribed clients) - Set up and control everything with one click.`,
	},
	{
		image: branding3,
		heading: <><span>Step 4:</span> launching</>,
		text: `Time to go live. We will host your new premium app in the apple store and google play. Last but not 
				least, we provide you with full maintenance of the app and dedicated 24/7 technical support. We got you.`,
	},
];

const HowItWorks = () => {
	// return (
	// 	<CommonSection
	// 		className={styles.sechoworks}
	// 		slides={slides}
	// 		title={
	// 			<>
	// 				<span className={styles.goldbold}>HOW IT WORKS</span>
	// 			</>
	// 		}
	// 		subtitle={<>
	// 			<span className={styles.head2}>We Made it Super Easy For You. Here’s the Step-by-Step Process to Build Your Fitness App.</span>
	// 		</>}
	// 	/>
	// );
	const maxStep = slides.length;

	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = useStep(maxStep);

	const isDesktop = useMediaQuery('(min-width: 768px)');
	const isMobile = useMediaQuery('(max-width: 767px)');
	const navigate = useNavigate();

	return (
		<section className={styles.container}>
			<div className={styles.block}>
				{isDesktop && (
					<FadeInOutSwiper
						currentSlide={currentStep}
						className={styles['image-block']}
					>
						{slides.map((slide, slideIndex) => {
							return (
								<img
									key={slideIndex}
									className={styles.image}
									src={slide.image}
									alt=''
								/>
							);
						})}
					</FadeInOutSwiper>
				)}
				<div className={styles['content-block']}>
					<Text
						className={styles.title}
						element='h2'
						variant='h1'
					>
						<span className={styles.goldbold}>HOW IT WORKS</span>
						<span className={styles.head2}>We Made it Super Easy For You. Here’s the Step-by-Step Process to Build Your Fitness App.</span>
					</Text>
					{isMobile && (
						<FadeInOutSwiper
							currentSlide={currentStep}
							className={styles['image-block']}
						>
							{slides.map((slide, slideIndex) => {
								return (
									<img
										key={slideIndex}
										className={styles.image}
										src={slide.image}
										alt=''
									/>
								);
							})}
						</FadeInOutSwiper>
					)}
					<div className={styles.content}>
						<FadeInOutSwiper currentSlide={currentStep}>
							{slides.map((slide, slideIndex) => {
								return (
									<div
										key={slideIndex}
										className={styles.profile}
									>

										<div>
											<Text
												element='h3'
												className={styles.heading2}
											>
												{slide.heading}
											</Text>
											<Text
												element='h3'
												className={styles.name}
											>
												{slide.name}
											</Text>
											<Text
												variant='base2'
												className={styles.position}
											>
												{slide.position}
											</Text>
										</div>
									</div>
								);
							})}
						</FadeInOutSwiper>
						<div>
							<HorizontalSwiper
								swipeTo={setStep}
								currentSlide={currentStep}
								allowTouchMove={true}
							>
								{slides.map((slide, slideIndex) => {
									return (
										<Text
											variant='base3'
											key={slideIndex}
											className={styles.text}
										>
											{slide.text}
										</Text>
									);
								})}
							</HorizontalSwiper>
						</div>
						<div className={styles.nav_div}>
							{isDesktop && (
								<Pagination

									itemsNumber={maxStep}
									activeItemIndex={currentStep}
									onClick={setStep}
								/>
							)}
							{isDesktop ? (
								<ArrowButtons
									goToPrevStep={goToPrevStep}
									canGoToPrevStep={canGoToPrevStep}
									goToNextStep={goToNextStep}
									canGoToNextStep={canGoToNextStep}

								/>
							) : (
								<Navigation
									setStep={setStep}
									maxStep={maxStep}
									currentStep={currentStep}
									goToPrevStep={goToPrevStep}
									canGoToPrevStep={canGoToPrevStep}
									goToNextStep={goToNextStep}
									canGoToNextStep={canGoToNextStep}

								/>
							)}
						</div>
						{/* {isMobile && ( */}
						<Button
							className={styles.button}
							text='TRY OUR BETA'
							onClick={() => navigate('/register')}
						/>
						{/* )} */}
					</div>
				</div>

			</div>


		</section>
	);
};

export default HowItWorks;
