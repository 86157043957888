import React from 'react';
import Button from '../../general/Button/Button';
import Input from '../../general/Input/Input';
import Text from '../../general/Text/Text';
import styles from './AuthForm.module.css';
import { Link } from 'react-router-dom';
import { SiGoogle } from 'react-icons/si';
import PropTypes from 'prop-types';

const AuthForm = ({ title, subtitle, inputs, buttonText, altLink, handleFormSubmit, handleInputChange }) => {
	return (
		<div className={styles.container}>
			<div>
				<Text
					element='h2'
					className={`${styles['weight-300']} ${styles.heading}`}
				>
					{title}
				</Text>
				<Text
					variant='base1'
					bold
					className={styles.subheading}
				>
					{subtitle}
				</Text>
			</div>
			<form onSubmit={handleFormSubmit}>
				<div className={styles.inputs}>
					{inputs.map((input, inputIndex) => {
						return (
							<Input
								key={inputIndex}
								type={input.type}
								id={input.id}
								label={input.label}
								placeholder={input.placeholder}
								name={input.name}
								onChange={handleInputChange}
								required={input.required}
							/>
						);
					})}
				</div>
				<div className={styles.buttons}>
					<Button
						text={buttonText}
						type='submit'
						onClick={handleInputChange}
						className={styles.button}
					/>
					{/*<Button*/}
					{/*	icon={<SiGoogle />}*/}
					{/*	text='Sign up with google'*/}
					{/*	type='submit'*/}
					{/*	onClick={handleInputChange}*/}
					{/*	className={styles.button}*/}
					{/*/>*/}
				</div>
			</form>
			{/*{altLink === 'register' ? (*/}
			{/*	<Text className={styles['weight-300']}>*/}
			{/*		Don't have an account?{' '}*/}
			{/*		<Link*/}
			{/*			to='/register'*/}
			{/*			className={styles.highlight}*/}
			{/*		>*/}
			{/*			Sign Up*/}
			{/*		</Link>*/}
			{/*	</Text>*/}
			{/*) : (*/}
			{/*	<Text className={styles['weight-300']}>*/}
			{/*		Already have an account?{' '}*/}
			{/*		<Link*/}
			{/*			to='/login'*/}
			{/*			className={styles.highlight}*/}
			{/*		>*/}
			{/*			Log In*/}
			{/*		</Link>*/}
			{/*	</Text>*/}
			{/*)}*/}
		</div>
	);
};

AuthForm.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	inputs: PropTypes.arrayOf(
		PropTypes.exact({
			type: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			placeholder: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
	buttonText: PropTypes.string.isRequired,
	altLink: PropTypes.oneOf(['register', 'login']).isRequired,
	handleFormSubmit: PropTypes.func.isRequired,
	handleInputChange: PropTypes.func.isRequired,
};

export default AuthForm;
