import React, { useState, useEffect } from 'react'
import styles from './PotentialIncome.module.css';
import Text from '../../general/Text/Text';
import insta from "../../../assets/pricing/insta.png";


const PotentialIncome = () => {
    const [followes, setFollowes] = useState(200000);
    const [subscriptionPrice, setSubscriptionPrice] = useState(9.99);
    const [conversionPercentage, setConversionPercentage] = useState(1);
    // const [monthly, setMonthly] = useState(2000);
    //
    //
    // useEffect(() => {
    //
    // }, [monthly]);
    return (
        <>

            <div className={styles.potential_sec_main} >
                <div className={styles.container_block} >
                    <div className={styles.potential_sec} >
                        <div className={styles.income_pott_col60}>
                            <div className={styles.income_pott_app}>
                                <svg width="54" height="54" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: '18px' }}>
                                    <path d="M15.6444 46.2222L26.4889 35.3778L33.6 42.4889L46.2222 29.9556V35.5556H53.3333V17.7778H35.5556V24.8889H41.1556L33.6 32.4444L26.4889 25.3333L10.6667 41.2444L15.6444 46.2222ZM7.11111 64C5.15556 64 3.48089 63.3043 2.08711 61.9129C0.695703 60.5191 0 58.8444 0 56.8889V7.11111C0 5.15556 0.695703 3.48089 2.08711 2.08711C3.48089 0.695703 5.15556 0 7.11111 0H56.8889C58.8444 0 60.5191 0.695703 61.9129 2.08711C63.3043 3.48089 64 5.15556 64 7.11111V56.8889C64 58.8444 63.3043 60.5191 61.9129 61.9129C60.5191 63.3043 58.8444 64 56.8889 64H7.11111Z" fill="#D9BB8B" />
                                </svg>
                                <Text
                                    element='h2'
                                    variant='h3'
                                    className={styles.highlight}
                                >
                                    POTENTIAL APP INCOME
                                </Text>

                                <Text
                                    variant='base1'
                                    className={styles.subtitle_main}
                                >
                                    How much <span className={styles.highlight}> money </span> are our coaches making on average?
                                </Text>
                                <div className={styles.follow_drop_div} >
                                    <img src={insta} alt="insta" />
                                    <Text
                                        variant='base1'
                                        className={styles.subtitle}
                                    >
                                        Instagram Followers
                                    </Text>
                                    <form className={styles.form_countt}>
                                        <input type='number' name="followers" style={{ padding: "10px" }} onChange={(e) => setFollowes(e.target.value)} value={followes} className={styles.dropdown_custom} />
                                        <p>followers</p>
                                    </form>
                                    {/* <select  >
										<option value="100000">100K</option>
										<option value="200000">200K</option>
										<option value="300000">300k</option>
									</select> */}
                                </div>
                                <div className={styles.follow_drop_div} >
                                    <img src={insta} alt="insta" />
                                    <Text
                                        variant='base1'
                                        className={styles.subtitle}
                                    >
                                        Subscription Price
                                    </Text>
                                    <form className={styles.form_countt2}>
                                        <input type='number' step='0.01' style={{ padding: "10px" }} name="followers" onChange={(e) => setSubscriptionPrice(e.target.value)} value={subscriptionPrice} className={styles.dropdown_custom} />
                                        <p>$/month</p>
                                    </form>
                                    {/* <select>
										<option value="10">9.99/month</option>

									</select> */}
                                </div>

                                <div className={styles.follow_drop_div} >
                                    <img src={insta} alt="insta" />
                                    <Text
                                        variant='base1'
                                        className={styles.subtitle}
                                    >
                                        Conversion Percentage
                                    </Text>
                                    <form className={styles.form_countt3}>
                                        <input type='number' style={{ padding: "10px" }} name="followers" onChange={(e) => (setConversionPercentage(e.target.value))} value={conversionPercentage} className={styles.dropdown_custom} />
                                        <p>% of Followers</p>
                                    </form>
                                    {/* <select >
										<option value="1">1% of Followers</option>
										<option value="2">2% of Followers</option>
										<option value="5">5% of Followers</option>
									</select> */}
                                </div>
                            </div>
                        </div>

                        <div className={styles.income_pott_col40}>
                            <div className={styles.income_revenue}>

                                <svg width="54" height="54" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: '18px' }}>
                                    <path d="M3.00001 0H51C51.7073 0 52.3855 0.280951 52.8856 0.781048C53.3857 1.28115 53.6667 1.95942 53.6667 2.66667V45.3333C53.6667 46.0406 53.3857 46.7189 52.8856 47.219C52.3855 47.7191 51.7073 48 51 48H3.00001C2.29277 48 1.61449 47.7191 1.11439 47.219C0.614295 46.7189 0.333344 46.0406 0.333344 45.3333V2.66667C0.333344 1.95942 0.614295 1.28115 1.11439 0.781048C1.61449 0.280951 2.29277 0 3.00001 0ZM17.6667 29.3333V34.6667H24.3333V40H29.6667V34.6667H32.3333C34.1015 34.6667 35.7971 33.9643 37.0474 32.714C38.2976 31.4638 39 29.7681 39 28C39 26.2319 38.2976 24.5362 37.0474 23.286C35.7971 22.0357 34.1015 21.3333 32.3333 21.3333H21.6667C21.3131 21.3333 20.9739 21.1929 20.7239 20.9428C20.4738 20.6928 20.3333 20.3536 20.3333 20C20.3333 19.6464 20.4738 19.3072 20.7239 19.0572C20.9739 18.8071 21.3131 18.6667 21.6667 18.6667H36.3333V13.3333H29.6667V8H24.3333V13.3333H21.6667C19.8986 13.3333 18.2029 14.0357 16.9526 15.286C15.7024 16.5362 15 18.2319 15 20C15 21.7681 15.7024 23.4638 16.9526 24.714C18.2029 25.9643 19.8986 26.6667 21.6667 26.6667H32.3333C32.687 26.6667 33.0261 26.8071 33.2761 27.0572C33.5262 27.3072 33.6667 27.6464 33.6667 28C33.6667 28.3536 33.5262 28.6928 33.2761 28.9428C33.0261 29.1929 32.687 29.3333 32.3333 29.3333H17.6667Z" fill="#D9BB8B" />
                                </svg>

                                <Text
                                    element='h2'
                                    variant='h3'
                                    className={styles.highlight}
                                >
                                    REVENUE
                                </Text>
                                <Text
                                    variant='base1'
                                    className={styles.revenuesubtitle}
                                >
                                    The prices are calculated based on.....
                                </Text>
                                <Text
                                    variant='h3'
                                    className={styles.subtitle}
                                >
                                    {' '}
                                    <Text
                                        variant='h3'
                                        className={styles.highlight}
                                    >
                                        {/* 2000 */}
                                        {/* {followes * (conversionPercentage / 100)} */}
                                        {(Math.ceil(followes * (conversionPercentage / 100))).toLocaleString('en-US', {maximumFractionDigits:2})}
                                        <span className={styles.font24}> monthly subscribers</span>
                                    </Text>{' '}

                                </Text>

                                <Text
                                    variant='base1'
                                    className={styles.subtitle}
                                >
                                    {' '}
                                    <Text
                                        variant='h3'
                                        className={styles.highlight}
                                    >
                                        {/* $19,980 */}
                                        ${(Math.ceil(Math.ceil(followes * (conversionPercentage / 100)) * subscriptionPrice)).toLocaleString('en-US', {maximumFractionDigits:2})}
                                        <span className={styles.font24}> gross monthly revenue*</span>
                                    </Text>{' '}

                                </Text>
                                <Text
                                    variant='base1'
                                    className={styles.subtitle}
                                >
                                    {' '}
                                    <Text
                                        variant='h3'
                                        className={styles.highlight}
                                    >
                                        {/* $239,760 */}
                                        ${(Math.ceil(Math.ceil(followes * (conversionPercentage / 100)) * subscriptionPrice) * 12).toLocaleString('en-US', {maximumFractionDigits:2})}
                                        <span className={styles.font24}> gross yearly revenue*</span>
                                    </Text>{' '}

                                </Text>

                            </div>
                        </div>
                    </div>
                    <Text element='base2' center className={styles.font24} >
                        <span>Gociety.io takes 0% of Your App Profits or Revenue</span>
                    </Text>
                </div>
            </div>
        </>
    )
}
export default PotentialIncome;
