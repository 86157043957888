import React from 'react';
import formatCurrency from '../../../helpers/formatCurrency';
import { useSelector } from 'react-redux';
import Text from '../../general/Text/Text';
import styles from './Summary.module.css';

const Summary = () => {
	const orderSummary = useSelector((state) => {
		const plan = state.checkout.plans.find((plan) => plan.selected).price;
		const addons = state.checkout.addons.filter((addon) => addon.checked).reduce((addonsSum, addon) => addonsSum + addon.price, 0);
		const tax = (plan + addons) * 0.1; // 10% tax
		const orderTotal = plan + addons + tax;

		return {
			subtotal: {
				label: 'Subtotal',
				price: plan,
			},
			addons: {
				label: 'Addons',
				price: addons,
			},
			promo: {
				label: 'Promo',
				price: null,
			},
			tax: {
				label: 'Tax',
				price: tax,
			},
			orderTotal: {
				label: 'Order Total',
				price: orderTotal,
			},
		};
	});

	return (
		<div className={styles.container}>
			<div className={styles.summary}>
				<Text
					element='h4'
					variant='base1'
					className={styles.heading}
				>
					Summary
				</Text>
				{Object.values(orderSummary).map((orderSummaryItem, orderSummaryItemIndex) => {
					return (
						<div
							className={styles['summary-item']}
							key={orderSummaryItemIndex}
						>
							<Text>{orderSummaryItem.label}:</Text>
							<Text bold>{orderSummaryItem.price ? formatCurrency(orderSummaryItem.price) : '--'}</Text>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Summary;
