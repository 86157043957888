import React from 'react';
import Text from '../../general/Text/Text';
import styles from './BrandingColorPicker.module.css';

function BrandingColorPicker({ colors, label, name, onChange, value, disabled }) {
	const handleChange = ({ target }) => {
		onChange(target.dataset.color);
	};

	return (
		<div>
			<Text>{label}</Text>
			<div className={styles.colors}>
				{colors.map((color, colorIndex) => {
					const id = color + colorIndex;
					return (
						<React.Fragment key={colorIndex}>
							<input
								className={styles.input}
								type='radio'
								id={id}
								name={name}
								checked={color === value}
								value={color}
								data-color={color}
								onChange={handleChange}
								disabled={disabled}
							/>
							<label
								style={{ backgroundColor: color }}
								className={styles.label}
								htmlFor={id}
							></label>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
}

export default BrandingColorPicker;
