import React from 'react';
import styles from './Text.module.css';
import PropTypes from 'prop-types';

function Text({ element, variant, children, className, bottomGutter, bold, center }) {
	const classes = `
		${styles[element] ? styles[element] : ''} 
		${styles[variant] ? styles[variant] : ''} 
		${className ? className : ''} 
		${bottomGutter ? styles['bottom-gutter'] : ''} 
		${bold ? styles.bold : ''} 
		${center ? styles.center : ''}
	`;

	switch (element) {
		case 'h1':
			return <h1 className={classes}>{children}</h1>;
		case 'h2':
			return <h2 className={classes}>{children}</h2>;
		case 'h3':
			return <h3 className={classes}>{children}</h3>;
		case 'h4':
			return <h4 className={classes}>{children}</h4>;
		case 'h5':
			return <h5 className={classes}>{children}</h5>;
		case 'h6':
			return <h6 className={classes}>{children}</h6>;
		case 'p':
			return <p className={classes}>{children}</p>;
		case 'span':
			return <span className={classes}>{children}</span>;
		default:
			return <p className={`${styles.p} ${classes}`}>{children}</p>;
	}
}

Text.propTypes = {
	element: PropTypes.oneOf(['p', 'span', 'base1', 'base2', 'base3', 'base4', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	variant: PropTypes.oneOf(['p', 'span', 'base1', 'base2', 'base3', 'base4', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	children: PropTypes.node,
	className: PropTypes.string,
	bottomGutter: PropTypes.bool,
	bold: PropTypes.bool,
	center: PropTypes.bool,
};

export default Text;
