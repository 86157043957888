import React from 'react';
import Pagination from '../../general/Pagination/Pagination';
import styles from './Navigation.module.css';
import Button from '../../general/Button/Button';

function Navigation({ maxStep, currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, setStep }) {
	return (
		<div className={styles.navigation}>
			<div className={styles.buttons}>
				<Button
					darkMode
					className={styles['left-button']}
					text='Skip for now'
					onClick={() => setStep(2)}
					hidden={canGoToPrevStep}
				/>
				<Button
					darkMode
					className={styles['right-button']}
					text='Next'
					onClick={goToNextStep}
					hidden={!canGoToNextStep}
				/>

				<Button
					darkMode
					className={styles['middle-button']}
					text='Join the waitlist'
					onClick={goToNextStep}
					hidden={currentStep + 1 !== maxStep}
				/>
			</div>
			<Pagination
				itemsNumber={maxStep}
				activeItemIndex={currentStep}
				variant='elongated'
				onClick={setStep}
				center
			/>
		</div>
	);
}

export default Navigation;
