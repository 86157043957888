import React, { useState } from 'react';
import { Link } from 'react-scroll';
import Text from '../../../general/Text/Text';
import styles from './FeaturesLayout.module.css';
import SubscriptionForm from '../../../Froms/SubscriptionForm/SubscriptionForm';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../../../../assets/home/branding-1.png';
import image2 from '../../../../assets/home/branding-2.png';
import Habit from '../../../../assets/home/Habit.png';
import image3 from '../../../../assets/home/branding-3.png';
// import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import community1 from '../../../../assets/home/community1.png';


import { WoodHand, communityTab, workoutTab, habitTab, cookingTab, community1, community2, community3, workout1, workout2, workout3, habit1, habit2, habit3, cooking1, cooking2, cooking3, Iphone14 } from "../../../../assets/home/index"
import Iphone from "../../../../assets/home/Iphone-14.png"

import CommonSection from '../CommonSection';


import useStep from '../../../../hooks/useStep';/////
import HorizontalSwiper from '../../../general/HorizontalSwiper/HorizontalSwiper';
import FadeInOutSwiper from '../../../general/FadeInOutSwiper/FadeInOutSwiper';
import Navigation from '../../../HomePage/Navigation/Navigation';

const features = [
	{
		text: 'Users can post their workout videos, receive direct feedback from you and engage with each other. Just like your favorite social media platforms.',
		image: {
			src: community1,
			alt: '',
		},
	},
	{
		text: 'Allow your clients to share any questions about your coaching program within the app. This could be the daily challenges, specific recipes adjustments, or their progress pictures. ',
		image: {
			src: community2,
			alt: '',
		},
	},
	{
		text: 'Hold the space for conversations about mindset, lifestyle, personal life,  supplements, fitness myths and anything you and your community will enjoy talking about.',
		image: {
			src: community3,
			alt: '',
		},
	},
];

const workoutHub = [
	{
		text: 'Allow exclusive access to all of your programs. Provide different difficulty levels for each client. Personalize everything.',
		image: {
			src: workout1,
			alt: '',
		},
	},
	{
		text: 'Easily upload, change, and customize all your workouts, programs, and challenges with your own dedicated admin panel.',
		image: {
			src: Iphone,
			alt: '',
		},
	},
	{
		text: 'Create fun and viral fitness challenges that attracts new users from your social media platforms.',
		image: {
			src: workout3,
			alt: '',
		},
	},
];

const habitCreator = [
	{
		text: 'This powerful feature will give your clients useful tools for them to use on a daily basis and build those new habits successfully. Users can syncs their favorite smartphones accessories.',
		image: {
			src: habit1,
			alt: '',
		},
	},
	{
		text: 'Keep your clients accountable by easily creating daily mental challenges for them to complete. Set up motivational push-notification to be sent automatically whenever you decide.',
		image: {
			src: habit2,
			alt: '',
		},
	},
	{
		text: 'Each user will have their own dedicated strength/body progress journal, steps/water tracker, and weekly planner where clients can choose all their workouts/diet for the week.',
		image: {
			src: habit3,
			alt: '',
		},
	},
];
const cookingSpace = [
	{
		text: 'An Interactive recipe hub with over 150 full easy-to-make recipes that will give your clients an attractive variety of breakfast, lunch and dinner.',
		image: {
			src: cooking1,
			alt: '',
		},
	},
	{
		text: 'Easily add and upload customized recipes of your own personal diet with your community. ',
		image: {
			src: cooking2,
			alt: '',
		},
	},
	{
		text: 'As a coach, cater to your high-ticket 1-on-1 clients by uploading custom meal plans & programs that only they can access through the app. ',
		image: {
			src: cooking3,
			alt: '',
		},
	},
];



function FeaturesLayout() {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const maxStep = features.length;

	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = useStep(maxStep);


	return (
		<>

			<div className={styles.feature_banner_section}>
				<Text
					element='h1'
					className={styles.title_main}
					center
				>
					The Tools of the New Leader
				</Text>
				<Text
					element='h4'
					className={styles.subtitle_main}
					center
				>
					We Equip Your App With The Most Essentials Tools and Features Your Clients Will Ever Need.
				</Text>

				{/* -----------------------------------------------------onResponsive ----------------------------------------------------------------------------*/}
				<div className={`${styles.icon_black_box} ${styles.icon_black_box_mob}`}>
					<Carousel activeIndex={index} onSelect={handleSelect}>

						<Carousel.Item>
							<div>
								<Link
									activeClass="active"
									className={styles.pointer}
									to="section1"
									spy={true}
									smooth={true}
									offset={0}
									duration={500}
								>
									<img src={communityTab} />
									<Text
										element='h6'
										className={styles.subtitle}
									>
										COMMUNITY
									</Text>
								</Link>
							</div>
							<div>
								<Link
									activeClass="active"
									className={styles.pointer}
									to="section2"
									spy={true}
									smooth={true}
									offset={0}
									duration={600}
								>
									<img src={workoutTab} />
									<Text
										element='h6'
										className={styles.subtitle}
									>
										WORKOUT HUB
									</Text>
								</Link>
							</div>
						</Carousel.Item>


						<Carousel.Item>
							<div>
								<Link
									activeClass="active"
									className={styles.pointer}
									to="section3"
									spy={true}
									smooth={true}
									offset={0}
									duration={800}
								>
									<img src={Habit} />
									<Text
										element='h6'
										className={styles.subtitle}
									>
										HABIT CREATOR
									</Text>
								</Link>
							</div>
							<div>
								<Link
									activeClass="active"
									className={styles.pointer}
									to="section4"
									spy={true}
									smooth={true}
									offset={0}
									duration={1000}
								>
									<img src={cookingTab} />
									<Text
										element='h6'
										className={styles.subtitle}
									>
										COOKING SPACE
									</Text>
								</Link>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>


				{/* ------------------------------------------------onDesktop ---------------------------------------------------------------------*/}
				<div className={styles.icon_black_box}>

					<Link
						activeClass="active"
						className={styles.pointer}
						to="section1"
						spy={true}
						smooth={true}
						offset={0}
						duration={500}
					>
						<img src={communityTab} />
						<Text
							element='h6'
							className={styles.subtitle}
						>
							COMMUNITY
						</Text>
					</Link>


					<Link
						activeClass="active"
						className={styles.pointer}
						to="section2"
						spy={true}
						smooth={true}
						offset={0}
						duration={600}
					>
						<img src={workoutTab} />
						<Text
							element='h6'
							className={styles.subtitle}
						>
							WORKOUT HUB
						</Text>
					</Link>

					<Link
						activeClass="active"
						className={styles.pointer}
						to="section3"
						spy={true}
						smooth={true}
						offset={0}
						duration={800}
					>
						<img src={Habit} />
						<Text
							element='h6'
							className={styles.subtitle}
						>
							HABIT CREATOR
						</Text>
					</Link>

					<Link
						activeClass="active"
						className={styles.pointer}
						to="section4"
						spy={true}
						smooth={true}
						offset={0}
						duration={1000}
					>
						<img src={cookingTab} />
						<Text
							element='h6'
							className={styles.subtitle}
						>
							COOKING SPACE
						</Text>
					</Link>

				</div>

			</div>




			<CommonSection
				id="section1"
				features={features}
				title='COMMUNITY FEED'
				subtitle='Your Very Own Social Fitness Platform'
				tabImage={communityTab}
				className={styles.slide_section_1}

			/>



			<CommonSection
				id="section2"
				features={workoutHub}
				title='WORKOUT HUB'
				subtitle='Host All Your Programs, Workouts and Challanges'
				theme='dark'
				reverse
				tabImage={workoutTab}
			/>

			<div className={styles.container}>
				<Text
					element='h2'
					variant='h1'
					center
					className={styles.subcribe_title}
				>
					TRY OUR BETA
				</Text>
				<Text
					variant='base4'
					center
					className={styles.subcribe_content}
				>
					Be the first to experience the future of fitness coaching. Join our exclusive beta-waitlist now and get early access to our revolutionary platform. Don’t miss out on this opportunity to transform the way you manage and grow your coaching business. Sign up today!
				</Text>
				<SubscriptionForm />
			</div>

			<CommonSection
				id="section3"
				features={habitCreator}
				title='HABIT CREATOR'
				subtitle='Make your App a Daily Need'
				tabImage={Habit}
				theme='light'
			/>

			<CommonSection
				id="section4"
				features={cookingSpace}
				title='COOKING SPACE'
				subtitle='Realistic and delicious recipies to explore'
				reverse
				tabImage={cookingTab}
			/>

		</>
	);
}

export default FeaturesLayout;
