import React, { useState, useEffect } from 'react';
import blackLogo from '../../assets/GocietyLogo1.png';
import whiteLogo from '../../assets/Gocietylogo2.png';
import styles from './Header.module.css';
import { MdOutlineMenu } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/general/Button/Button';
import Text from '../../components/general/Text/Text';
import useMediaQuery from '../../hooks/useMediaQuery';
import PropTypes from 'prop-types';

const pages = [
	{
		title: 'Home',
		path: '/',
		type: 'link',
	},
	{
		title: 'Features',
		path: '/features',
		type: 'link',
	},
	{
		title: 'Pricing',
		path: '/pricing',
		type: 'link',
	},
	// {
	// 	title: 'Blog',
	// 	path: '/blog',
	// 	type: 'link',
	// },
	{
		title: 'TRY OUR BETA',
		path: '/register',
		type: 'button',
	},
];

function Header({ transparentBg, lightLogo, lightMode, pricingSection }) {
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const [isScroll, setisScroll] = useState(false);

	useEffect(() => {
		const handleScroll = (e) => {
			if (window.scrollY > 50) {
				setisScroll(true)
			} else {
				setisScroll(false)
			}
		}
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll);
	}, [])



	const handleMenuClick = () => {
		setIsMenuOpen((prev) => !prev);
	};

	const handleBackdropClick = (e) => {
		if (e.target.dataset.element !== 'backdrop') return;
		setIsMenuOpen((prev) => !prev);
	};

	const desktop = useMediaQuery('(min-width: 920px)');

	const nav = (
		<nav className={`${styles['nav']}`}>
			<ul>
				{pages.map((page, pageIndex) => {
					if (page.type === 'button') {
						return (
							<li key={pageIndex}>
								<Button
									text={page.title}
									onClick={() => navigate(page.path)}
								/>
							</li>
						);
					}

					return (
						<li key={pageIndex}>
							<Link to={page.path}>
								<Text
									element='span'
									variant='base2'
									className={`${styles['link-text']} ${lightMode && styles['link-text-white']}`}
									bold
								>
									{page.title}
								</Text>
							</Link>
						</li>
					);
				})}
			</ul>
		</nav>
	);

	const sideMenu = (
		<div
			data-element='backdrop'
			className={`${styles.backdrop} ${isMenuOpen ? styles.open : ''}`}
			onClick={handleBackdropClick}
		>
			<div className={styles['side-menu']}>
				<div className={styles['heading-container']}>
					<button
						type='button'
						onClick={handleMenuClick}
						className={styles.button}
					>
						<MdClose size={38} />
					</button>
					<Text
						className={styles.heading}
						element='h3'
					>
						MENU
					</Text>
				</div>
				{nav}
			</div>
		</div>
	);
	return (
		<>
			<div className={`${styles.container} ${transparentBg ? styles['transparent-bg'] : ''} ${pricingSection ? styles.goldenbutton : ""} ${isScroll ? styles.scrollClass : ""} ${(lightMode && !isScroll) && styles['header-light-top']}`}>
				<div className={styles.banner}>
					<div className={styles.bannerContent}>
						Get ready to experience something amazing - we are launching our <b>FREE BETA</b> soon!
					</div>
				</div>
				<div className={styles.header}>
					{!desktop && (
						<button
							className={styles.button}
							type='button'
							onClick={handleMenuClick}
						>
							<MdOutlineMenu size={33} />
						</button>
					)}
					<div className={styles.logo}>
						<Link to='/' className={styles.logoLink}>
							<img
								src={lightLogo || lightMode || !desktop ? whiteLogo : blackLogo}
								alt='Gociety.io logo white'
							/>
						</Link>
						<Text className={styles.logoBeta}>(BETA)</Text>
					</div>

					{desktop ? (
						nav
					) : (
						<button
							className={styles.button}
							type='button'
							onClick={handleMenuClick}
						>
							<FaUserCircle size={26} />
						</button>
					)}
				</div>
			</div>
			{!desktop && sideMenu}
		</>
	);
}

Header.propTypes = {
	transparentBg: PropTypes.bool,
	lightLogo: PropTypes.bool,
	lightMode: PropTypes.bool,
	pricingSection: PropTypes.bool,
};

export default Header;
