import React from 'react';
import styles from './Pagination.module.css';
import PropTypes from 'prop-types';

function Pagination({ variant, itemsNumber, activeItemIndex, onClick, center, darkMode }) {
	const handlePaginationClick = ({ target }) => {
		const itemIndex = target.dataset.index;
		onClick(Number(itemIndex));
	};

	return (
		<div className={`${styles.container} ${variant === 'elongated' ? styles.elongated : ''} ${center ? styles.center : ''}`}>
			{[...Array(itemsNumber)].map((item, index) => {
				return (
					<div
						onClick={handlePaginationClick}
						data-index={index}
						key={index}
						className={`${styles.item} ${Number(activeItemIndex) === index ? styles.active : ''} ${darkMode ? styles.dark : ''}`}
					></div>
				);
			})}
		</div>
	);
}

Pagination.propTypes = {
	variant: PropTypes.oneOf(['elongated', 'default']),
	itemsNumber: PropTypes.number.isRequired,
	activeItemIndex: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	center: PropTypes.bool,
	darkMode: PropTypes.bool,
};

export default Pagination;
