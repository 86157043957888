import React, {useEffect} from 'react';
import useStep from '../../../hooks/useStep';
import Header from '../../../layout/Header/Header';
import styles from './BrandingLayout.module.css';
import Navigation from '../../Checkout/Navigation/Navigation';
import { Branding1, Branding2, Checkout1, Checkout2 } from '../../Checkout/Cards/Cards';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Pagination from '../../general/Pagination/Pagination';
import BrandingPlansCarousel from '../BrandingPlansCarousel/BrandingPlansCarousel';
import Summary from '../../Checkout/Summary/Summary';
import Button from '../../general/Button/Button';
import AppDemo from '../AppDemo/AppDemo';
import {useDispatch, useSelector} from 'react-redux';

import { updateAddons, finishWaitlist } from "../../../redux/slices/checkoutSlice";

function BrandingLayout() {
	const dispatch = useDispatch();
	const cardsMaxStep = 4;

	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, setStep } = useStep(cardsMaxStep);

	useEffect(() => {
		if (!canGoToNextStep) {
			dispatch(finishWaitlist());
		}
	}, [canGoToNextStep]);

	const plans = useSelector((state) => state.checkout.plans);
	const plansCarouselUseStep = useStep(plans.length);

	const desktop = useMediaQuery('(min-width: 920px)');

	if (desktop) {
		return (
			<div className={styles['d-container']}>
				<Header
					// transparentBg
					// lightLogo
				/>
				<div className={styles['d-left-block']}>
					<div className={styles['d-left-block-content']}>
						<Pagination
							itemsNumber={cardsMaxStep}
							activeItemIndex={currentStep}
							variant='elongated'
							onClick={setStep}
						/>

						<div className={styles['d-view-window']}>
							<div
								className={styles['d-cards-container']}
								style={{ transform: `translateX(-${100 * currentStep}%)` }}
							>
								<Branding1 />
								<Branding2 />
								<Checkout1 plansCarouselUseStep={plansCarouselUseStep} />
								<Checkout2 plansCarouselUseStep={plansCarouselUseStep} />
							</div>
						</div>
						<div className={styles.buttons}>
							<Button
								className={styles['left-button']}
								text='Skip for now'
								onClick={() => setStep(2)}
								hidden={canGoToPrevStep}
							/>
							<Button
								className={styles['right-button']}
								text='Next'
								onClick={goToNextStep}
								hidden={!canGoToNextStep}
							/>

							{/*<Button*/}
							{/*	className={styles['middle-button']}*/}
							{/*	text='Join the waitlist'*/}
							{/*	onClick={goToNextStep}*/}
							{/*	hidden={currentStep + 1 !== cardsMaxStep}*/}
							{/*/>*/}
						</div>
					</div>
				</div>
				<div className={styles['d-right-block']}>
					<div
						className={styles['d-right-upper-block']}
						style={{ height: currentStep > 1 && '60%' }}
					>
						<AppDemo hasSwitch={currentStep < 2} />
					</div>
					<div className={styles['d-right-lower-block']}>
						<div
							className={styles['d-middle-container']}
							style={{ opacity: currentStep > 1 && 1 }}
						>
							<BrandingPlansCarousel
								plans={plans}
								plansCarouselUseStep={plansCarouselUseStep}
							/>
						</div>
						<Summary />
					</div>
				</div>
			</div>
		);
	}

	// return (
	// 	<div className={styles['m-container']}>
	// 		<Header />
	// 		<div
	// 			className={styles['m-cards-container']}
	// 			style={{ transform: `translateX(-${100 * currentStep}%)` }}
	// 		>
	// 			<Branding1 />
	// 			<Branding2 />
	// 			<Checkout1
	// 				plans={plans}
	// 				plansCarouselUseStep={plansCarouselUseStep}
	// 			/>
	// 			<Checkout2
	// 				plans={plans}
	// 				plansCarouselUseStep={plansCarouselUseStep}
	// 			/>
	// 		</div>
	// 		<Navigation
	// 			maxStep={cardsMaxStep}
	// 			currentStep={currentStep}
	// 			canGoToNextStep={canGoToNextStep}
	// 			canGoToPrevStep={canGoToPrevStep}
	// 			goToNextStep={goToNextStep}
	// 			setStep={setStep}
	// 		/>
	// 	</div>
	// );
}

export default BrandingLayout;
