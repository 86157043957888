import React from 'react';
import BrandingLayout from '../components/Branding/BrandingLayout/BrandingLayout';
import PageContainer from '../components/general/PageContainer/PageContainer';
import useTitle from '../hooks/useTitle';

const Branding = () => {
	useTitle('Branding | Gociety.io');

	return (
		<PageContainer>
			<BrandingLayout />
		</PageContainer>
	);
};

export default Branding;
