import React from 'react';
import SubscriptionForm from '../../Froms/SubscriptionForm/SubscriptionForm';
import styles from './Footer.module.css';
import Text from '../../general/Text/Text';


function Footer() {
	return (
		<div className={styles.container}>
			<Text
				element='h2'
				variant='h1'
				center
				className={styles.goldbold}
			>
				Try our beta
			</Text>
			<Text
				variant='base4'
				center
				className={styles.subtitle}
			>
				Be the first to experience the future of fitness coaching. Join our exclusive beta-waitlist now and get early access to our revolutionary platform. Don’t miss out on this opportunity to transform the way you manage and grow your coaching business. Sign up today!			</Text>
			<SubscriptionForm />

		</div>
	);
}

export default Footer;
