import React, { useEffect, useRef } from 'react';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import Text from '../../general/Text/Text';
import styles from './BrandingPlansCarousel.module.css';
import { MdArrowForwardIos } from 'react-icons/md';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { changePlan } from '../../../redux/slices/checkoutSlice';
import formatCurrency from '../../../helpers/formatCurrency';

const RoundButton = ({ children, onClick, className, hide }) => {
	return (
		<button
			onClick={onClick}
			className={`${hide && styles.hide} ${styles.button} ${className}`}
			type='button'
		>
			{children}
		</button>
	);
};

const BrandingPlansCarousel = ({ plansCarouselUseStep }) => {
	const container = useRef(null);
	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = plansCarouselUseStep;
	const plans = useSelector((state) => state.checkout.plans);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			changePlan({
				title: plans[currentStep].title,
			})
		);
	}, [currentStep]);

	useEffect(() => {
		const createCustomVhVar = () => {
			const height = container.current.clientHeight;
			document.documentElement.style.setProperty('--plan-carousel-height', `${height}px`);
		};
		createCustomVhVar();
	});

	return (
		<div
			className={styles.container}
			ref={container}
		>
			<HorizontalSwiper
				spaceBetween={5}
				currentSlide={currentStep}
				swipeTo={setStep}
			>
				{plans.map((plan, planIndex) => {
					return (
						<div
							className={styles.plan}
							key={planIndex}
						>
							{/*<div className={styles.image}>*/}
							{/*	<img*/}
							{/*		src={plan.image.src}*/}
							{/*		alt={plan.image.alt}*/}
							{/*	/>*/}
							{/*</div>*/}
							<div className={styles.details}>
								<Text element='h3'>{plan.title}</Text>
								<Text variant='base1'>
									<Text
										element='span'
										variant='base1'
										bold
									>
										{formatCurrency(plan.price)}
									</Text>{' '}
									per month
								</Text>
							</div>
						</div>
					);
				})}
			</HorizontalSwiper>
			<RoundButton
				className={styles['button-prev']}
				onClick={goToPrevStep}
				hide={!canGoToPrevStep}
			>
				<MdArrowBackIosNew
					size={60}
					color='#000000'
				/>
			</RoundButton>
			<RoundButton
				className={styles['button-next']}
				onClick={goToNextStep}
				hide={!canGoToNextStep}
			>
				<MdArrowForwardIos
					size={60}
					color='#000000'
				/>
			</RoundButton>
		</div>
	);
};

export default BrandingPlansCarousel;
