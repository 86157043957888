import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';
import router from './routes/router';


const createCustomVhVar = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const App = () => {
	const createCustomVhVarDebounced = useDebouncedCallback(createCustomVhVar, 200);
	createCustomVhVarDebounced();

	useEffect(() => {
		window.addEventListener('resize', createCustomVhVar);
		return () => window.removeEventListener('resize', createCustomVhVar);
	}, []);

	return <RouterProvider router={router} />;
};
export default App;
