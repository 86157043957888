import React from 'react';
import styles from './Button.module.css';
import Text from '../Text/Text';
import PropTypes from 'prop-types';

function Button({ text, disabled, onClick, className, hidden, type = 'button', icon }) {

	return (
		<button
			disabled={disabled}
			className={`${styles.button} ${hidden ? styles.hidden : null} ${className}`}
			type={type}
			onClick={onClick}
		>
			{icon}
			<Text
				element='span'
				variant='base2'
				bold
			>
				{text}
			</Text>
		</button>
	);
}

Button.propTypes = {
	text: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	hidden: PropTypes.bool,
	type: PropTypes.string,
	icon: PropTypes.element,
};

export default Button;
