import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { isMobile } from 'detect-touch-device';

const FadeInOutSwiper = ({ children, currentSlide, className }) => {
	const swiper = useRef(null);

	useEffect(() => {
		if (!swiper.current) return;
		const swiperInstance = swiper.current.swiper;

		swiperInstance.slideTo(currentSlide);
	}, [currentSlide, swiper.current]);

	return (
		<Swiper
			className={className}
			spaceBetween={50}
			ref={swiper}
			speed={isMobile ? 400 : 600}
			allowTouchMove={false}
			modules={[EffectFade]}
			effect='fade'
			fadeEffect={{ crossFade: true }}
		>
			{children.map((child, index) => {
				return <SwiperSlide key={index}>{child}</SwiperSlide>;
			})}
		</Swiper>
	);
};

export default FadeInOutSwiper;
