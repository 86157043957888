import React from 'react';
import styles from './CommonSection.module.css';
import useStep from '../../../hooks/useStep';
import FadeInOutSwiper from '../../general/FadeInOutSwiper/FadeInOutSwiper';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import Text from '../../general/Text/Text';
import { useNavigate } from 'react-router-dom';
import Button from '../../general/Button/Button';
import Navigation from '../Navigation/Navigation';
import useMediaQuery from '../../../hooks/useMediaQuery';

const CommonSection = ({ slides, reverse, title, subtitle }) => {
	const navigate = useNavigate();

	const maxStep = slides.length;

	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = useStep(maxStep);

	const isDesktop = useMediaQuery('(min-width: 920px)');

	const heading = (
		<div className={styles.heading}>
			<Text
				element='h2'
				variant='h1'
			>
				{title}
			</Text>
			<Text variant='base4'>{subtitle}</Text>
		</div>
	);

	return (
		<div className={styles.allHolderParent}>
			<div className={styles.allHolder}>
				<section className={`${styles.container} ${reverse ? styles.reverse : ''}`}>
					{!isDesktop && heading}
					<FadeInOutSwiper
						currentSlide={currentStep}
						className={styles['image-block']}
					>
						{slides.map((slide, slideIndex) => {
							return (
								<img
									key={slideIndex}
									className={styles.image}
									src={slide.image}
									alt=''
								/>
							);
						})}
					</FadeInOutSwiper>
					<div className={styles['content-block']}>
						{isDesktop && heading}
						<div className={styles.swiper}>
							<HorizontalSwiper
								swipeTo={setStep}
								currentSlide={currentStep}
								allowTouchMove={true}
							>
								{slides.map((slide, slideIndex) => {
									return (
										<React.Fragment key={slideIndex}>
											<Text
												element='h3'
												className={styles.highlight}
											>
												{slide.heading}
											</Text>
											<Text variant='base3' className={styles.mob_font24}>{slide.text}</Text>
										</React.Fragment>
									);
								})}
							</HorizontalSwiper>
						</div>
						<Navigation
							setStep={setStep}
							maxStep={maxStep}
							currentStep={currentStep}
							goToPrevStep={goToPrevStep}
							canGoToPrevStep={canGoToPrevStep}
							goToNextStep={goToNextStep}
							canGoToNextStep={canGoToNextStep}
						/>

						<Button
							className={styles.button}
							text='TRY OUR BETA'
							onClick={() => navigate('/register')}
						/>
					</div>
				</section>
			</div>
		</div>


	);
};

export default CommonSection;
