import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CommonSection.module.css';
import Text from '../../general/Text/Text';

import useStep from '../../../hooks/useStep';
import useMediaQuery from '../../../hooks/useMediaQuery';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import FadeInOutSwiper from '../../general/FadeInOutSwiper/FadeInOutSwiper';
import Navigation from '../../HomePage/Navigation/Navigation';

function CommonSection({ title, subtitle, features, theme, reverse, id, tabImage }) {
	const [currentFeature, setCurrentFeature] = useState(0);

	const maxStep = features.length;//////
	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = useStep(maxStep);
	const isDesktop = useMediaQuery('(min-width: 768px)');


	const handleTextHover = (e) => {
		setCurrentFeature(Number(e.currentTarget.dataset.index));
	};
	const heading = (
		<div className={`${styles.heading} ${styles.spacing_mobb1}`} id={id}>
			<Text
				element='h2'
				variant='h1'
			>
				{title}
			</Text>
			<Text variant='base4'>{subtitle}</Text>
		</div>
	);

	if (!isDesktop) {
		return (

			<>
				{heading}
				<FadeInOutSwiper
					currentSlide={currentStep}
					className={styles['image-block']}
				>
					{features.map((slide, slideIndex) => {
						return (
							<img
								key={slideIndex}
								className={styles.image}
								src={slide.image.src}
								alt=''
							/>
						);
					})}
				</FadeInOutSwiper>
				<div className={`${styles.spacing_mobb2}`} id={id}>
					<div className={styles['content-block']}>

						<div className={styles.swiper}>
							<HorizontalSwiper
								swipeTo={setStep}
								currentSlide={currentStep}
								allowTouchMove={true}
							>
								{features.map((slide, slideIndex) => {
									return (
										<React.Fragment key={slideIndex}>
											<Text
												element='h3'
												className={styles.highlight}
											>
												{slide.heading}
											</Text>
											<Text variant='base3' className={styles.mob_font24}>{slide.text}</Text>
										</React.Fragment>
									);
								})}
							</HorizontalSwiper>
						</div>
						<Navigation
							setStep={setStep}
							maxStep={maxStep}
							currentStep={currentStep}
							goToPrevStep={goToPrevStep}
							canGoToPrevStep={canGoToPrevStep}
							goToNextStep={goToNextStep}
							canGoToNextStep={canGoToNextStep}
						/>
					</div>
				</div>
			</>

		)

	}

	return (
		<section
			className={`${styles.container} ${theme ? (theme === 'light' ? styles['light-theme'] : styles['dark-theme']) : ''} ${reverse ? styles.reverse : ''
				}`} id={id}
		>

			<div className={styles['start-block']}>
				<div className={styles.content}>
					<img
						src={tabImage}
						alt={tabImage}
					/>
					<Text
						element='h3'
						className={styles.highlight}
					>
						{title}
					</Text>
					<Text
						element='base3'
						className={styles.subtitle}
					>
						{subtitle}
					</Text>
					<div className={styles.features}>
						{features.map((feature, featureIndex) => {
							return (
								<div
									className={`${styles.feature} ${currentFeature === featureIndex ? styles['current-feature'] : ''}`}
									data-index={featureIndex}
									onMouseEnter={handleTextHover}
									key={featureIndex}
								>
									<Text element='base1'>{feature.text}</Text>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className={styles['end-block']}>
				<div className={styles.images}>
					{features.map((feature, featureIndex) => {
						return (
							<div
								key={featureIndex}
								className={`${styles['image-container']} ${currentFeature === featureIndex ? styles.reveal : ''}`}
							>
								<img
									className={styles.image}
									src={feature.image.src}
									alt={feature.image.alt}
								/>
							</div>
						);
					})}
				</div>
			</div>

		</section>
	);
}

CommonSection.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	features: PropTypes.arrayOf(
		PropTypes.exact({
			text: PropTypes.string.isRequired,
			image: PropTypes.exact({
				src: PropTypes.string.isRequired,
				alt: PropTypes.string.isRequired,
			}).isRequired,
		})
	).isRequired,
	theme: PropTypes.oneOf(['light', 'dark']),
	reverse: PropTypes.bool,
};

export default CommonSection;
