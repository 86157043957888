import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthForm from '../AuthForm/AuthForm';
import {setName, setEmail, setIg } from '../../../redux/slices/checkoutSlice';
import {useDispatch} from "react-redux";

const inputs = [
	{ type: 'text', id: 'name', label: 'Enter you name', placeholder: 'Name', name: 'name', value: '', required: true },
	{ type: 'email', id: 'email', label: 'Enter you email', placeholder: 'Email', name: 'email', value: '', required: true },
	{ type: 'text', id: 'ig', label: 'Instagram Username', placeholder: 'Instagram Username', name: 'ig', value: '', required: false },
	// { type: 'password', id: 'password', label: 'Enter you password', placeholder: 'Password', name: 'password' },
];

const RegisterForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleInputChange = (e) => {
		if (e.target.id === 'name') {
			dispatch(setName(e.target.value));
		}
		if (e.target.id === 'email') {
			dispatch(setEmail(e.target.value));
		}if (e.target.id === 'ig') {
			dispatch(setIg(e.target.value));
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		navigate('/branding');
	};

	return (
		<AuthForm
			title='Join The Waitlist'
			subtitle='Be the first to experience the future of fitness coaching. Join our exclusive beta-waitlist now and get early access to our revolutionary platform.'
			inputs={inputs}
			buttonText='Try Beta'
			altLink='login'
			handleFormSubmit={handleFormSubmit}
			handleInputChange={handleInputChange}
		/>
	);
};

export default RegisterForm;
