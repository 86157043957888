import { createBrowserRouter } from 'react-router-dom';
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import Checkout from '../pages/Checkout';
import Branding from '../pages/Branding';
import Pricing from '../pages/Pricing';
import Features from '../pages/Features';
import Login from '../pages/Login';
import Register from '../pages/Register';

export default createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/branding',
		element: <Branding />,
	},
	{
		path: '/features',
		element: <Features />,
	},
	{
		path: '/pricing',
		element: <Pricing />,
	},
	{
		path: '/blog',
		element: <Blog />,
	},
	{
		path: '/checkout',
		element: <Checkout />,
	},
]);
