import React from 'react';
import RegisterForm from '../components/Froms/RegisterForm/RegisterForm';
import PageContainer from '../components/general/PageContainer/PageContainer';
import SplitSection from '../components/general/SplitSection/SplitSection';
import useTitle from '../hooks/useTitle';

const Register = () => {
	useTitle('Sign Up | Gociety.io');

	return (
		<PageContainer>
			<SplitSection startSection={<RegisterForm />} />
		</PageContainer>
	);
};

export default Register;
