import React, {useEffect} from 'react';
import PageContainer from '../components/general/PageContainer/PageContainer';
import PricingLayout from '../components/PricingPage/PricingLayout/PricingLayout';
import useTitle from '../hooks/useTitle';
import Header from '../layout/Header/Header';
import Footermain from '../components/general/Footermain/Footermain';
// import Footer from '../layout/Footer/Footer';

const Pricing = () => {
	useTitle('Pricing | Gociety.io');

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<PageContainer>
			<Header lightMode={true} pricingSection />
			<main>
				<PricingLayout />
			</main>
			<Footermain />
		</PageContainer>
	);
};

export default Pricing;
