import React, { useState } from 'react';
import Text from '../../general/Text/Text';
import ToggleSwitch from '../../general/ToggleSwitch/ToggleSwitch';
import { useSelector } from 'react-redux';
import PricingTier from '../PricingTier/PricingTier';
import styles from './PricingLayout.module.css';
import { ImArrowUp2 } from 'react-icons/im';
import appManager from "../../../assets/pricing/appManager.png"
import brandBook from "../../../assets/pricing/brandBook.png"
import marketing from "../../../assets/pricing/marketing.png"
import our_mob1 from "../../../assets/home/our_mob1.jpg"
import our_mob2 from "../../../assets/home/our_mob2.jpg"
import our_mob3 from "../../../assets/home/our_mob3.jpg"
import insta from "../../../assets/pricing/insta.png"
// import arrow from "../../../assets/Vector.png"
import PotentialIncome from '../../general/PotentialIncome/PotentialIncome';

function PricingLayout() {
	const [isToggleChecked, setIsToggleChecked] = useState(false);
	const [followes, setFollowes] = useState(100000);
	const [subscriptionPrice, setSubscriptionPrice] = useState(10);
	const [conversionPercentage, setConversionPercentage] = useState(1);
	const [show, setShow] = useState(false)



	console.log(subscriptionPrice)

	const billingType = isToggleChecked === true ? 'yearly' : 'monthly';

	const { plans, addons } = useSelector((state) => state.checkout);
	console.log(addons);


	const handleToggleChange = () => {
		setIsToggleChecked((prev) => !prev);
	};

	return (
		<>
			<div className={styles.bg_black_half}>

			</div>
			<div className={styles.container}>

				<Text
					element='h1'
					className={styles.heading}
					center
				>
					TIME TO{' '}
					<Text
						element='span'
						variant='h1'
						className={styles.highlight}
					>
						REALLY
					</Text>{' '}
					GROW YOUR BUSINESS
				</Text>
				<Text
					variant='base1'
					className={styles.subtitle_banner}
					center
				>
					Join Our Waitlist and Try Our Beta for FREE!
				</Text>
				<div className={styles.toggle_main_div}>
					{/*<ToggleSwitch*/}
					{/*	label='Billing type'*/}
					{/*	onChange={handleToggleChange}*/}
					{/*	checked={isToggleChecked}*/}
					{/*	name='billing-type'*/}
					{/*	id='billing-type'*/}
					{/*	leftValue='Bill Monthly'*/}
					{/*	rightValue='Bill Yearly'*/}
					{/*	color='light'*/}
					{/*/>*/}
					{/*<Text*/}
					{/*	className={styles['free-trial']}*/}
					{/*	center*/}
					{/*	bold*/}
					{/*>*/}
					{/*	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
					{/*		<path d="M10.875 19.3001L4.275 12.7001C4.175 12.6001 4.104 12.4917 4.062 12.3751C4.02067 12.2584 4 12.1334 4 12.0001C4 11.8667 4.02067 11.7417 4.062 11.6251C4.104 11.5084 4.175 11.4001 4.275 11.3001L10.875 4.70008C11.0583 4.51675 11.2873 4.42075 11.562 4.41208C11.8373 4.40408 12.075 4.50008 12.275 4.70008C12.475 4.88342 12.5793 5.11242 12.588 5.38708C12.596 5.66242 12.5 5.90008 12.3 6.10008L7.4 11.0001H18.575C18.8583 11.0001 19.096 11.0957 19.288 11.2871C19.4793 11.4791 19.575 11.7167 19.575 12.0001C19.575 12.2834 19.4793 12.5207 19.288 12.7121C19.096 12.9041 18.8583 13.0001 18.575 13.0001H7.4L12.3 17.9001C12.4833 18.0834 12.5793 18.3167 12.588 18.6001C12.596 18.8834 12.5 19.1167 12.3 19.3001C12.1167 19.5001 11.8833 19.6001 11.6 19.6001C11.3167 19.6001 11.075 19.5001 10.875 19.3001Z" fill="#FF8A00" />*/}
					{/*	</svg>*/}

					{/*	Get 3 months FREE!*/}
					{/*	/!* <ImArrowUp2 /> *!/*/}
					{/*</Text>*/}
				</div>
				<div className={styles.plans}>
					{plans.map((plan, planIndex) => {
						return (
							<PricingTier
								key={planIndex}
								pricingNote={plan.note}
								title={plan.title}
								price={plan.price}
								perks={plan.perks}
								billingType={billingType}
								soon={plan.soon}
								buttonText={plan.buttonText}
								monthly
							/>
						);
					})}
				</div>

			</div>
			<div className={'anchor-holder'}>
				<div id='income' className={'anchor'}></div>
			</div>
			<PotentialIncome/>
			<div className={`${styles.container} ${styles.containerNoPd}`}>


				<div className={styles['section-heading']}>
					<Text
						element='h2'
						className={styles.highlight}
					>
						Addons
					</Text>
					{/* <Text variant='base1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text> */}
				</div>


				{/*----------------- ONCLICK---------------------------------------- */}
				<div className={styles['section-heading']} onClick={() => (setShow(!show))}>
					<Text
						element='h2'
						className={styles.mobile_accor_head}
					>
						Addons
						<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.5 10.5L12.5 15.5L17.5 10.5H7.5Z" fill="black" />
						</svg>

					</Text>

					{/* <Text variant='base1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text> */}
				</div>

				{/*----------------- ONCLICK---------------------------------------- */}
				<div className={'anchor-holder'}>
					<div id='addons' className={'anchor'}></div>
				</div>
				<div className={`${styles.addons} ${show ? (`${styles.showAddContent}`) : (``)}`}>
					{addons.map((addon, planIndex) => {
						return (
							<PricingTier
								key={planIndex}
								pricingNote={addon.pricingNote}
								title={addon.title}
								price={addon.price}
								monthly={false}
								perks={addon.perks}
								billingType={billingType}
								buttonText={addon.buttonText}
							/>
						);
					})}
				</div>
			</div >














			{/*<div className={'anchor-holder'}>*/}
			{/*	<div id='manager' className={'anchor'}></div>*/}
			{/*</div>*/}
			{/*<div className={`${styles.centerDiv} ${styles.appManager_main} ${styles.box_common_main}`} >*/}
			{/*	<div className={styles.box_image}>*/}
			{/*		<img src={appManager} alt="appManager" />*/}
			{/*	</div>*/}

			{/*	<div className={styles.box_contenttt}>*/}
			{/*		<Text*/}
			{/*			element='span'*/}
			{/*			variant='h1'*/}
			{/*			className={styles.highlight}*/}
			{/*		>*/}
			{/*			APP MANAGER*/}
			{/*		</Text>*/}
			{/*		<div className={styles.box_image_mob}>*/}
			{/*			<img src={our_mob1} alt="brandBook" />*/}
			{/*		</div>*/}
			{/*		<Text*/}
			{/*			variant='base3'*/}
			{/*			className={styles.subtitle}*/}
			{/*		>*/}
			{/*			Get an In-House App Manager to join your team and handle tasks such as uploading workouts, managing the community, and setting up payments, freeing up your time. This service is ideal for time-strapped coaches.*/}
			{/*		</Text>*/}

			{/*	</div>*/}
			{/*</div>*/}

			{/*<div className={'anchor-holder'}>*/}
			{/*	<div id='strategy' className={'anchor'}></div>*/}
			{/*</div>*/}

			{/*<div className={`${styles.centerDiv} ${styles.Marketingg_main} ${styles.box_common_main}`} >*/}

			{/*	<div className={`${styles.box_contenttt} ${styles.box_contenttt_left}`}>*/}
			{/*		<Text*/}
			{/*			element='span'*/}
			{/*			variant='h1'*/}
			{/*			className={styles.highlight}*/}
			{/*		>*/}
			{/*			MARKETING STRATEGY*/}
			{/*		</Text>*/}
			{/*		<div className={styles.box_image_mob}>*/}
			{/*			<img src={our_mob2} alt="brandBook" />*/}
			{/*		</div>*/}
			{/*		<Text*/}
			{/*			variant='base3'*/}
			{/*			className={styles.subtitle}*/}
			{/*		>*/}
			{/*			Our team will handle your marketing strategy and help you effectively target your audience. This service is designed for busy coaches who don't have the time to manage marketing tasks like running ads and setting up email campaigns.*/}
			{/*		</Text>*/}
			{/*	</div>*/}
			{/*	<div className={styles.box_image}>*/}
			{/*		<img src={marketing} alt="marketing" />*/}
			{/*	</div>*/}

			{/*</div>*/}
			{/*<div className={'anchor-holder'}>*/}
			{/*	<div id='book' className={'anchor'}></div>*/}
			{/*</div>*/}
			{/*<div className={`${styles.centerDiv} ${styles.brandbook_main} ${styles.box_common_main}`} >*/}

			{/*	<div className={styles.box_image}>*/}
			{/*		<img src={brandBook} alt="brandBook" />*/}
			{/*	</div>*/}
			{/*	<div className={styles.box_contenttt}>*/}
			{/*		<Text*/}
			{/*			element='span'*/}
			{/*			variant='h1'*/}
			{/*			className={styles.highlight}*/}
			{/*		>*/}
			{/*			BRAND BOOK*/}
			{/*		</Text>*/}
			{/*		<div className={styles.box_image_mob}>*/}
			{/*			<img src={our_mob3} alt="brandBook" />*/}
			{/*		</div>*/}
			{/*		<Text*/}
			{/*			variant='base3'*/}
			{/*			className={styles.subtitle}*/}
			{/*		>*/}
			{/*			Our branding experts will work with you to understand your unique style and vision for your business to create a distinctive brand identity reflecting your values and resonating with your audience that will drive growth and new revenue capabilities.*/}
			{/*		</Text>*/}

			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
}

export default PricingLayout;
