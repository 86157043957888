import { useCallback } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

const useStep = (maxStep, stepLength = 1) => {
    maxStep -= 1;
    const [currentStep, setCurrentStep] = useState(0);

    const canGoToNextStep = useMemo(() => {
        return currentStep + stepLength <= maxStep;
    }, [maxStep, currentStep]);

    const canGoToPrevStep = useMemo(() => {
        return currentStep - stepLength >= 0;
    }, [currentStep]);


    const goToNextStep = useCallback(() => {
        if (canGoToNextStep) {
            setCurrentStep(step => step + stepLength);
        }
    }, [canGoToNextStep]);

    const goToPrevStep = useCallback(() => {
        if (canGoToPrevStep) {
            setCurrentStep(step => step - stepLength);
        }
    }, [canGoToPrevStep]);

    const setStep = useCallback((step) => {
        if (step >= 0 && step <= maxStep) {
            setCurrentStep(step);
        }
    }, [maxStep]);

    return { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep };
};

export default useStep;