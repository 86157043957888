import React from 'react';
import Pagination from '../../general/Pagination/Pagination';
import styles from './Navigation.module.css';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

export function ArrowButtons({ goToPrevStep, canGoToPrevStep, goToNextStep, canGoToNextStep, darkMode }) {
	return (
		<div className={`${styles['nav-buttons']} ${darkMode ? styles.dark : ''}`}>
			<button
				className={styles.button}
				type='button'
				onClick={goToPrevStep}
				disabled={!canGoToPrevStep}
			>
				<MdOutlineArrowBackIos />
			</button>
			<button
				className={styles.button}
				type='button'
				onClick={goToNextStep}
				disabled={!canGoToNextStep}
			>
				<MdOutlineArrowForwardIos />
			</button>
		</div>
	);
}

function Navigation({ setStep, maxStep, currentStep, goToPrevStep, canGoToPrevStep, goToNextStep, canGoToNextStep, darkMode, className }) {
	return (
		<div className={`${styles.container} ${className}`}>
			<Pagination
				darkMode={darkMode}
				onClick={setStep}
				itemsNumber={maxStep}
				activeItemIndex={currentStep}
			/>

			<ArrowButtons
				goToPrevStep={goToPrevStep}
				canGoToPrevStep={canGoToPrevStep}
				goToNextStep={goToNextStep}
				canGoToNextStep={canGoToNextStep}
				darkMode={darkMode}
			/>
		</div>
	);
}

export default Navigation;
