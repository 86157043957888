import React from 'react';
import styles from './Testimonials.module.css';
import HorizontalSwiper from '../../general/HorizontalSwiper/HorizontalSwiper';
import FadeInOutSwiper from '../../general/FadeInOutSwiper/FadeInOutSwiper';
import useStep from '../../../hooks/useStep';
import Navigation from '../Navigation/Navigation';
import Text from '../../general/Text/Text';
import Pagination from '../../general/Pagination/Pagination';
import { ArrowButtons } from '../Navigation/Navigation';

//import images
import reviewPicture0 from '../../../assets/home/Iphoneimg.png';
import profilePicture1 from '../../../assets/home/review-person-1.png';
import profilePicture2 from '../../../assets/home/review-person-2.png';
import reviewPicture1 from '../../../assets/home/review-1.png';
import reviewPicture2 from '../../../assets/home/review-2.png';
import useMediaQuery from '../../../hooks/useMediaQuery';

const slides = [
	{
		profilePicture: profilePicture2,
		name: 'Samuel Okunola',
		position: 'Founder of @samokfit',
		image: reviewPicture2,
		text: `When I first considered investing in this project, my main concern was the amount of time I would need to dedicate to it. However, working with the team proved to be an incredibly efficient and stress-free experience. All I had to do was provide some branding materials and pictures, and the team quickly delivered a final product that exceeded my expectations.`,
	},
	{
		profilePicture: profilePicture1,
		name: 'JJ Lentic',
		position: 'Co-founder of @ketofitacademy',
		image: reviewPicture1,
		text: `Great team. Throughout the entire process, their customer support was highly professional and made the collaboration easy. The app was delivered faster than I anticipated, and the backend portal to manage the app and our clients was extremely user-friendly. Definetely worth the investment for this valuable tool for our online coaching`,
	},
];

const Testimonials = () => {
	const maxStep = slides.length;

	const { currentStep, canGoToNextStep, canGoToPrevStep, goToNextStep, goToPrevStep, setStep } = useStep(maxStep);

	const isDesktop = useMediaQuery('(min-width: 920px)');
	const isMobile = useMediaQuery('(max-width: 919px)');

	return (
		<section className={styles.container}>
			<div className={styles.block}>
				{isMobile && (
					<Text
						className={styles.goldbold}
						element='h2'
						variant='h1'
					>
						OUR CLIENTS
					</Text>
				)}
				<FadeInOutSwiper
					currentSlide={currentStep}
					className={styles['image-block']}
				>
					{slides.map((slide, slideIndex) => {
						return (
							<img
								key={slideIndex}
								className={styles.image}
								src={slide.image}
								alt=''
							/>
						);
					})}
				</FadeInOutSwiper>

				<div className={styles['content-block']}>
					{isDesktop && (
						<Text
							className={styles.title}
							element='h2'
							variant='h1'
						>
							OUR CLIENTS
						</Text>
					)}
					<div className={styles.content}>
						<FadeInOutSwiper currentSlide={currentStep}>
							{slides.map((slide, slideIndex) => {
								return (
									<div
										key={slideIndex}
										className={styles.profile}
									>
										<div className={styles.avatar}>
											<img
												src={slide.profilePicture}
												alt={`${slide.name} avatar`}
											/>
										</div>
										<div>
											<Text
												element='h3'
												className={styles.name}
											>
												{slide.name}
											</Text>
											<Text
												variant='base2'
												className={styles.position}
											>
												{slide.position}
											</Text>
										</div>
									</div>
								);
							})}
						</FadeInOutSwiper>
						<div>
							<HorizontalSwiper
								swipeTo={setStep}
								currentSlide={currentStep}
								allowTouchMove={true}
							>
								{slides.map((slide, slideIndex) => {
									return (
										<Text
											variant='base3'
											key={slideIndex}
											className={styles.text}
										>
											{slide.text}
										</Text>
									);
								})}
							</HorizontalSwiper>
						</div>
						{isDesktop && (
							<Pagination
								darkMode
								itemsNumber={maxStep}
								activeItemIndex={currentStep}
								onClick={setStep}
							/>
						)}
					</div>
				</div>
			</div>

			{isDesktop ? (
				<ArrowButtons
					goToPrevStep={goToPrevStep}
					canGoToPrevStep={canGoToPrevStep}
					goToNextStep={goToNextStep}
					canGoToNextStep={canGoToNextStep}
					darkMode
				/>
			) : (
				<Navigation
					setStep={setStep}
					maxStep={maxStep}
					currentStep={currentStep}
					goToPrevStep={goToPrevStep}
					canGoToPrevStep={canGoToPrevStep}
					goToNextStep={goToNextStep}
					canGoToNextStep={canGoToNextStep}
					darkMode
				/>
			)}
		</section>
	);
};

export default Testimonials;
