import React, {useEffect} from 'react';
import FeaturesLayout from '../components/features_page/CommonSection/FeaturesLayout/FeaturesLayout';
import PageContainer from '../components/general/PageContainer/PageContainer';
import useTitle from '../hooks/useTitle';
import Header from '../layout/Header/Header';
import Footermain from '../components/general/Footermain/Footermain';

const Features = () => {
	useTitle('Features | Gociety.io');

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<PageContainer>
			<Header />
			<main>
				<FeaturesLayout />
			</main>
			<Footermain />
		</PageContainer>
	);
};

export default Features;
