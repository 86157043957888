import React, {useState} from 'react';
import Input from '../../general/Input/Input';
import BrandingAddOn from '../../Branding/BrandingAddOn/BrandingAddOn';
import Summary from '../Summary/Summary';
import Addons from '../Addons/Addons';
import BrandingColorPicker from '../../Branding/BrandingColorPicker/BrandingColorPicker';
import Text from '../../general/Text/Text';
import BrandingPlansCarousel from '../../Branding/BrandingPlansCarousel/BrandingPlansCarousel';
import styles from './Cards.module.css';
import useMediaQuery from '../../../hooks/useMediaQuery';
import AppDemo from '../../Branding/AppDemo/AppDemo';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddons, setPrimaryColor, setBackgroundColor, setLogo, setBrandName } from '../../../redux/slices/checkoutSlice';
import { MdOutlineCloudUpload } from 'react-icons/md';

export function Branding1() {
	const dispatch = useDispatch();

	const desktop = useMediaQuery('(min-width: 920px)');

	const brandingAddon = useSelector((state) => state.checkout.addons.find((addon) => addon.name === 'brand-book'));
	const { brandName, customMode } = useSelector((state) => state.checkout);

	const [brand, setBrand] = useState('')
	const [addons, setAddons] = useState([])
	const [color, setColor] = useState([])

	const handleBrandName = (e) => {
		dispatch(setBrandName(e.target.value));
		setBrand(e.target.value)
	};

	const handleAddonChange = ({ target }) => {
		dispatch(updateAddons({ name: target.name }));
		setAddons(addons => [...addons, target.name]);
	};

	const handleSetLogo = (e) => {
		const imageFiles = e.target.files;
		const imageFilesLength = imageFiles.length;
		if (imageFilesLength > 0) {
			const imageSrc = URL.createObjectURL(imageFiles[0]);
			dispatch(setLogo(imageSrc));
		}
	};

	const heading = (
		<div className={styles['heading-container']}>
			<Text
				element='h2'
				className={styles.heading}
			>
				App Branding
			</Text>
			<Text
				variant='base1'
				bold
			>
				Upload your Brand Icon and Logo
			</Text>
		</div>
	);

	const fileInput = (
		<div className={styles['file-input-field']}>
			<label
				htmlFor='brand-logo'
				className={styles['file-input-label']}
			>
				<Text element='span'>Upload Logo</Text>
			</label>
			<label
				htmlFor='brand-logo'
				className={styles['file-input-button']}
			>
				<Text
					element='span'
					variant='base2'
					bold
					className={styles['file-input-text']}
				>
					<MdOutlineCloudUpload size={20} /> ADD LOGO
				</Text>
			</label>
			<input
				className={styles['file-input']}
				type='file'
				id='brand-logo'
				accept='image/*'
				onChange={handleSetLogo}
				disabled={!customMode}
			/>
		</div>
	);

	if (desktop) {
		return (
			<div className={styles['d-container']}>
				{heading}
				<Input
					id='brand-name'
					name='brand-name'
					type='text'
					value={brandName}
					placeholder='Add Brand Name'
					onChange={handleBrandName}
				/>
				{fileInput}
				<BrandingAddOn
					label='No Branding? Select addon to get custom branding'
					id={brandingAddon.name + brandingAddon.title}
					name={brandingAddon.name}
					value={brandingAddon.title}
					price={brandingAddon.price}
					checked={brandingAddon.checked}
					onChange={handleAddonChange}
				/>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles['upper-container']}>
				<div className={styles['upper-componenet']}>
					<AppDemo hasSwitch={true} />
				</div>
			</div>
			<div className={styles.content}>
				{heading}
				<Input
					id='brand-name'
					name='brand-name'
					type='text'
					value={brandName}
					placeholder='Add Brand Name'
					onChange={handleBrandName}
				/>
				{fileInput}
				<BrandingAddOn
					label='No Branding? Select addon to get custom branding'
					id={brandingAddon.name + brandingAddon.title}
					name={brandingAddon.name}
					value={brandingAddon.title}
					price={brandingAddon.price}
					checked={brandingAddon.checked}
					onChange={handleAddonChange}
				/>
			</div>
		</div>
	);
}

const backgroundColors = ['#FFFFFE', '#EFF0F3', '#004643', '#272343', '#242424'];
const primaryColors = ['#F582AE', '#FFD803', '#FEA501', '#7F5AF0', '#00EBC7'];

export function Branding2() {
	const dispatch = useDispatch();

	const desktop = useMediaQuery('(min-width: 920px)');

	const { primaryColor, backgroundColor } = useSelector((state) => state.checkout.colors);
	const { customMode } = useSelector((state) => state.checkout);

	const handlePrimaryColorChange = (color) => {
		dispatch(setPrimaryColor(color));
	};

	const handleBackgroundColorChange = (color) => {
		dispatch(setBackgroundColor(color));
	};

	const heading = (
		<div className={styles['heading-container']}>
			<Text
				element='h2'
				className={styles.heading}
			>
				App Branding
			</Text>
			<Text
				variant='base1'
				bold
			>
				Upload Workout Images and Select Brand Colors
			</Text>
		</div>
	);

	const colorPickers = (
		<div className={styles['color-pickers']}>
			<BrandingColorPicker
				colors={backgroundColors}
				label='Select Background Color'
				name='bg-colors'
				onChange={handleBackgroundColorChange}
				value={backgroundColor}
				disabled={!customMode}
			/>
			<BrandingColorPicker
				colors={primaryColors}
				label='Select Primary Color'
				name='primary-colors'
				onChange={handlePrimaryColorChange}
				value={primaryColor}
				disabled={!customMode}
			/>
		</div>
	);

	if (desktop) {
		return (
			<div className={styles['d-container']}>
				<div>
					{heading}
					{colorPickers}
				</div>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles['upper-container']}>
				<div className={styles['upper-componenet']}>
					<AppDemo hasSwitch={true} />
				</div>
			</div>
			<div className={styles.content}>
				{heading}
				{colorPickers}
			</div>
		</div>
	);
}

export function Checkout1({ plansCarouselUseStep }) {
	const desktop = useMediaQuery('(min-width: 920px)');

	const heading = (
		<div className={styles['heading-container']}>
			<Text
				element='h2'
				className={styles.heading}
			>
				Payment Details
			</Text>
			<Text
				variant='base1'
				bold
			>

			</Text>
		</div>
	);

	if (desktop) {
		return (
			<div className={styles['d-container']}>
				{heading}
				<Addons />
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles['upper-container']}>
				<div className={styles['upper-componenet']}>
					<AppDemo />
				</div>
				<div className={styles['middle-container']}>
					<BrandingPlansCarousel plansCarouselUseStep={plansCarouselUseStep} />
				</div>
			</div>
			<div className={`${styles.content} ${styles['top-space']}`}>
				{heading}
				<Addons />
				<Summary />
			</div>
		</div>
	);
}

export function Checkout2({ plansCarouselUseStep }) {
	const desktop = useMediaQuery('(min-width: 920px)');

	const heading = (
		<div className={`${styles['heading-container']} ${styles.waitinglist}`}>
			<Text
				element='h2'
				className={styles.heading}
				center
			>
				Thank you
			</Text>
			<Text
				variant='base1'
				className={styles.subheading}
				center
			>
				You've successfully joined the waitlist.
			</Text>
		</div>
	);

	if (desktop) {
		return <div className={styles['d-container']}>{heading}</div>;
	}

	return (
		<div className={styles.container}>
			<div className={styles['upper-container']}>
				<div className={styles['upper-componenet']}>
					<AppDemo />
				</div>
				<div className={styles['middle-container']}>
					<BrandingPlansCarousel plansCarouselUseStep={plansCarouselUseStep} />
				</div>
			</div>
			<div className={`${styles.content} ${styles['top-space']}`}>
				{heading}
				<div className={styles['summary-container']}>
					<Summary />
				</div>
			</div>
		</div>
	);
}
