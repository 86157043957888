import React, { useContext } from 'react';
import styles from './Hero.module.css';
import Header from '../../../layout/Header/Header';
import { useNavigate } from 'react-router-dom';
import videoPlaceholder from '../../../assets/home/home4x/Video.jpg';
import Text from '../../general/Text/Text';
import Button from '../../general/Button/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { SwiperContext } from '../../general/PageSwiper/PageSwiper';

const Hero = () => {
	const navigate = useNavigate();
	const { swipeToNextSection } = useContext(SwiperContext);

	return (
		<section className={styles.container}>
			<Header />
			<div className={styles.video_main}>
				<video
					className={styles.video}
					autoPlay
					loop
					muted
					playsInline
					poster={videoPlaceholder}
					src={null}
				>
					<source
						src={null}
						type='video/mp4'
					></source>
				</video>
				{/* <span className={styles.video_iconn}>
					<svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17.7246 33.5575L34.2676 23.3461L17.7246 13.1347V33.5575ZM23.6328 46.0382C20.3636 46.0382 17.2913 45.4421 14.416 44.25C11.5407 43.0594 9.03955 41.443 6.91259 39.4007C4.78564 37.3584 3.1022 34.9569 1.86226 32.196C0.620755 29.4351 0 26.4852 0 23.3461C0 20.207 0.620755 17.2571 1.86226 14.4962C3.1022 11.7353 4.78564 9.33376 6.91259 7.29148C9.03955 5.24919 11.5407 3.63201 14.416 2.43992C17.2913 1.24934 20.3636 0.654053 23.6328 0.654053C26.902 0.654053 29.9743 1.24934 32.8496 2.43992C35.7249 3.63201 38.2261 5.24919 40.353 7.29148C42.48 9.33376 44.1634 11.7353 45.4033 14.4962C46.6448 17.2571 47.2656 20.207 47.2656 23.3461C47.2656 26.4852 46.6448 29.4351 45.4033 32.196C44.1634 34.9569 42.48 37.3584 40.353 39.4007C38.2261 41.443 35.7249 43.0594 32.8496 44.25C29.9743 45.4421 26.902 46.0382 23.6328 46.0382Z" fill="white"/>
					</svg>
				</span>	 */}
			</div>
			<div className={styles.content}>
				<div className={styles.contentInner}>
					<Text element='h1' className={styles.herotitle}>
						<span className={styles.hlc}>EVERYTHING</span> YOUR CLIENTS<br/> NEED IN YOUR <span className={styles.hlc}>OWN APP.</span>
					</Text>
					<Text
						variant='base1'
						className={[styles.subtitle]}
					>
						Convert Social Media Followers into a Monetized Fitness Community.
					</Text>
					<Button
						className={styles.buttonhero}
						text='TRY OUR BETA'
						onClick={() => navigate('/register')}
					/>
				</div>

			</div>
			<button
				className={styles['next-section']}
				onClick={swipeToNextSection}
			>
				<MdKeyboardArrowDown />
				<MdKeyboardArrowDown />
			</button>
		</section>
	);
};

export default Hero;
